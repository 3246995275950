import React, { useState, useEffect, useReducer } from 'react';
import NavigationTab from "./components/common/NavigationTab.component";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import * as loginService from './service/loginService';
// import useMoengage from './hooks/useMoengage';
import { createMoengageUser } from './service/engagementMonitoringService';
import { reducer } from './reducers/mitraReducer';
import { Alert, notification, Spin } from 'antd';
import CONSTANTS from './constants/constants';
import Lottie from 'react-lottie';

import "bootstrap/dist/css/bootstrap.min.css";
import './main.scss';

import { ROLES, ROUTES } from './components/common/roles';
import { suiteCrmMLView } from './constants/utils';
import { getVLBrandDetails } from './service/accountService';
import Animation from './static/lottie/vahan-pro.lottie.json'
import UniversalTopBar from './components/TopBar/UniversalTopBar';
import DesktopSidebar from './components/DesktopSidebar/DesktopSidebar';

const { ErrorBoundary } = Alert;

window.dataLayer = window.dataLayer || [];
function gtag() { window.dataLayer.push(arguments); }
gtag('js', new Date());
gtag('config', 'G-S7YGR6V3MG');
ReactGA.initialize('UA-204663297-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export const AppContext = React.createContext();

function App() {
  const [userRole, setUserRole] = useState(ROLES.ML);
  const [mitraReducer, dispatchMitra] = useReducer(reducer, null);
  const [alert, setAlert] = useState({
    display: null, //false or true when actived
    text: 'Error Found!!',
    type: 'error'  //success , info , warning  or error
  })
  const [spin, setSpin] = useState({
    loading: false, //false or true when actived
    delay: null,
    tip: 'Loading...'  //Loading copy here
  })
  // const [createMoengageUser, trackEvent] = useMoengage();
  const [variant, setVariant] = useState(null);
  const [showPoweredBy, setShowPoweredBy] = useState(false);
  const [isCustomURL, setIsCustomURL] = useState(false);

  const [animationComplete, setAnimationComplete] = useState(false);
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: Animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleAnimationComplete = () => {
    setAnimationComplete(true);
  };

  useEffect(() => {
    loginService
      .validateSession()
      .then((response) => {
        // setting user role
        if (localStorage.getItem('isFromSuiteCrm') && suiteCrmMLView(response.data.mitra.id))
          setUserRole(ROLES.DC);

        // if onboarding questions are not answered then take to onboarding screen
        if (!response.data.mitra.isFeedBackCompleted && window.location.pathname !== '/onboarding-questions') {
          window.location.pathname = '/onboarding-questions';
        }
        if (response && response.data && response.data.termsVersion > 0) {
          dispatchMitra({ type: 'termsVersion', value: true })
        } else {
          dispatchMitra({ type: 'termsVersion', value: false })
        }
        localStorage.setItem('mitraId', response?.data?.mitra?.id);
        dispatchMitra({ type: 'LoginInfo', value: response.data.mitra })
        if (response.data.features) {
          dispatchMitra({ type: 'EXPERIMENTAL_FEATURES', value: response.data.features })
          dispatchMitra({ type: 'ALLOWED_FEATURES', value: response.data.features })
        }
        if (process.env.REACT_APP_GOOGLE_OPTIMIZE_EXPERIMENT_ID) {
          gtag('event', 'optimize.callback', {
            name: process.env.REACT_APP_GOOGLE_OPTIMIZE_EXPERIMENT_ID,
            callback: (_variant) => {
              setVariant(_variant)
            }
          });
        }
        createMoengageUser(response.data.mitra);
      })
      .catch(error => {
        console.error('Error:', error);
        if (
          window.location.pathname !== '/'
          && !window.location.pathname.includes('/job-postings')
          && !window.location.pathname.includes('/job-posting')
          && !window.location.pathname.includes('/upload-onboarding-data')
          && !window.location.pathname.includes('/upload-periscope-data')
          && !window.location.pathname.includes('/upload-marketing-data')
          && !window.location.pathname.includes('/file-uploader')
          && !window.location.pathname.includes('/signup')
          && !window.location.pathname.includes('/terms-and-conditions')
          && !window.location.pathname.includes('/rp/')
          && !window.location.pathname.includes('/ai-call-consent')
        ) {
          window.location.pathname = '/';
        }
      });
  }, []);

  useEffect(() => {
    if (alert?.display === true) {
      setTimeout(() => {
        setAlert({
          display: false,
          text: '',
          type: ''
        })
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    if (window.location.hostname) {
      // True when the current URL is a non-gigshare URL
      const showVahanBranding = CONSTANTS.NON_CUSTOM_DOMAINS.some(term => window.location.hostname.includes(term))
      if (!showVahanBranding) {
        setIsCustomURL(true)
      }
      if (mitraReducer?.mitraInfo && !showVahanBranding && !showPoweredBy) {
        // if (mitraReducer.mitraInfo.brandName && mitraReducer.mitraInfo.brandLink) {
        setShowPoweredBy(true);
        fetchCustomBrandDetails()
        // }
      }
    }

    const rootElement = document.getElementById('root');

    if (mitraReducer?.allowedFeatures?.[CONSTANTS.VAHAN_PRO_ACCESS]?.isEnabledOverall) {
      setUserRole(ROLES.VAHAN_PRO_USER);
      rootElement.classList.add('vahanPro');
      document.body.classList.add('vahanPro');

      rootElement.classList.remove('vahanOld');
      document.body.classList.remove('vahanOld');
    } 
    else if (mitraReducer?.allowedFeatures?.[CONSTANTS.SUPPORT_AUTOMATION_ACCESS]?.isEnabledOverall) {
      setUserRole(ROLES.SUPPORT_AUTOMATION_ACCESS_USER);
      rootElement.classList.remove('vahanPro');
      document.body.classList.remove('vahanPro');

      rootElement.classList.add('vahanOld');
      document.body.classList.add('vahanOld');
    }
    else {
      rootElement.classList.remove('vahanPro');
      document.body.classList.remove('vahanPro');

      rootElement.classList.add('vahanOld');
      document.body.classList.add('vahanOld');
    }
  }, [mitraReducer?.mitraInfo, mitraReducer?.allowedFeatures])

  useEffect(() => {
    if(mitraReducer?.userRole) {
      setUserRole(mitraReducer?.userRole);
    }
  }, [mitraReducer?.userRole]);

  const fetchCustomBrandDetails = () => {
    let params = {
      brandLink: sanitisePageURL(window.location.href)
    }

    getVLBrandDetails(params).then((brandDetailsRes) => {
      if (brandDetailsRes?.data) {
        dispatchMitra({ type: 'CUSTOM_BRAND_DETAILS', value: brandDetailsRes?.data.data }); // changing the property name as per response(20th Dec'24)
        setFavicon(brandDetailsRes?.data?.data?.logo)
        document.title = brandDetailsRes?.data?.data?.brandName
      }
    })
  }

  const sanitisePageURL = (urlStr) => {
    // To not send 'https://' and last '/' in getVLBrandDetails payload
    return urlStr.replace(/^https:\/\//, '').replace(/\/$/, '');
  }

  const setFavicon = (url) => {
    if (url) {
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = url;

      // Remove existing favicon link if any and append the new one
      const head = document.getElementsByTagName('head')[0];
      const existingLink = head.querySelector("link[rel*='icon']");
      if (existingLink) {
        head.removeChild(existingLink);
      }
      head.appendChild(link);
    }
  }

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    console.log('Complete Cache Cleared')
  };

  clearCacheData();
  return (
    <>
      {alert?.display && <Alert size="large" banner message={alert.text} type={alert.type} afterClose={() => setAlert(null)} showIcon />}
      <div className="App">
        <ErrorBoundary>

        {!animationComplete && mitraReducer?.allowedFeatures?.[CONSTANTS.VAHAN_PRO_ACCESS]?.isEnabledOverall ? (
          <div className='d-flex align-items-center justify-content-center' style={{height: '100vh', width: '100%'}}>
            <Lottie 
              options={defaultOptions}
              height={400}
              width={400}
              speed={1.3}
              eventListeners={[
                {
                  eventName: 'complete',
                  callback: handleAnimationComplete,
                },
              ]}
            />
          </div>
        ) : (
          <>
            <AppContext.Provider value={{ mitraReducer, mitraDispatch: dispatchMitra, setAlert, setSpin, userRole, setUserRole }}>
              <Router
                getUserConfirmation={() => {
                  /* Empty callback to block the default browser prompt */
                }}
              >
                <div className='d-flex flex-column w-100'>
                  {
                    mitraReducer?.allowedFeatures?.[CONSTANTS.VAHAN_PRO_ACCESS]?.isEnabledOverall  ? (
                      <UniversalTopBar />
                    ): null
                  }
                  <div className='d-flex'>
                    {
                      mitraReducer?.mitraInfo && !mitraReducer?.allowedFeatures?.[CONSTANTS.VAHAN_PRO_ACCESS]?.isEnabledOverall &&
                      mitraReducer?.mitraInfo?.hideSidebar!=='true' && 
                      mitraReducer.mitraInfo.isFeedBackCompleted && <div className="navigation-container"><NavigationTab /></div>
                    }
                    {
                      mitraReducer?.mitraInfo && mitraReducer?.allowedFeatures?.[CONSTANTS.VAHAN_PRO_ACCESS]?.isEnabledOverall &&
                      mitraReducer?.mitraInfo?.hideSidebar!=='true' && 
                      mitraReducer.mitraInfo.isFeedBackCompleted && <div className="navigation-container"><DesktopSidebar /></div>
                    }
                    <Spin spinning={spin.loading} deplay={spin.delay} tip={spin.tip}>
                      <Switch>
                        {
                          ROUTES[userRole].map((route, index) => {
                            return <Route key={index} path={route.path} component={route.component} exact={route.exact} >
                              {
                                route?.redirect ? <Redirect to={route.redirect} /> : null
                              }
                            </Route>
                          })
                        }
                      </Switch>
                    </Spin>
                  </div>
                </div>
              </Router>
            </AppContext.Provider>

            {
              showPoweredBy ?
                <div className='app-powered-by d-flex align-items-center'>
                  <span className='regular16-24 black45'>
                    Powered By Vahan
                  </span>
                  <img className="powered-by-footer-img" alt="Powered By Vahan" src="/image/vahan-new-logo.png" />
                </div>
                :
                null
            }
          </>
        )}
        </ErrorBoundary>
      </div>
    </>
  );
}

export default App;
