import React, { useState, useEffect, useContext, useReducer } from 'react';
import CandidateProfilePopup from './candidateProfilePopup.component'
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import moment from "moment";
import useGAEventsTracker from '../../service/useGSEventsTracker';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import { DatePicker, Button, Tag, Space, Typography, notification, Pagination, Empty, Select, Modal } from 'antd';
import { AppContext } from '../../App';
import { getMitraTeamMembersList, getActiveClientsForFilter, disabledDateForRecuiterDashboard, getStatusListForFilters, getLocationForFilter } from './../../service/commonService'
import { ThunderboltFilled, LockOutlined, FireOutlined, InfoCircleOutlined, FilterOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { showHotLeadsPopUpBackend, recruiterDashboardCandidateList, recruiterDashboardCandidateListDownload } from './../../service/leadService'
import SideSheet from "../common/SideSheet"
import PageHeaderComponent from '../common/PageHeader';
import '../../css/lead.scss';
import RecruiterCard from './recruiterCard';
import commonFunc from "../../utils/commonFunction";
import { rollOutCallHistory } from '../../constants/utils';
import CandidateProfilePopupV2 from './candidateProfilePopupV2Components/candidateProfilePopupV2';
import SearchComponent from '../common/globalSearch';
import { updateNotifiedIncomingCalls } from '../../service/kaleyraCallService';
import RaiseIssueSider from '../help/RaiseIssueSider.component';
import VerifyEmailPopup from './../common/VerifyEmailPopup.component';
import HotLeadsModal from '../leads/HotLeadsModal';
import NewLoader from '../common/NewLoader/NewLoader';
import { Switch } from "antd";
import axios from 'axios';
import ReassignLeadsDialog from './ReassignLeads/ReassignLeadsDialog';
import { ROLES } from '../common/roles';
import SupportChatBot from '../HelpAutomation/components/SupportChatBot';

const { Text, Link } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const RecruiterPage = () => {
	let getfirstDateOfMonth = () => {
		const firstDate = moment().startOf('month').format('YYYY-MM-DD');
		return firstDate;
	}
	const history = useHistory();
	const [pathNameCandidates, setPathNameCandidates] = useState(window.location.href.indexOf("candidates") > -1)
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);
	const GAEventsTracker = useGAEventsTracker("Action buttons clicked")
	const AllCandidatesGAEventsTracker = useGAEventsTracker("Action buttons clicked")
	const CriticalCandidatesGAEventsTracker = useGAEventsTracker("Action buttons clicked")
	const { mitraReducer, setAlert, setSpin, mitraDispatch, userRole } = useContext(AppContext);
	const [leads, setLeads] = useState([]);
	const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD')); //Api initial sending 30 days gap
	const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
	const [showDetail, setShowDetail] = useState(false);
	const [selectedLead, setSelectedLead] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [mitra, setMitra] = useState();
	const [filterSider, setfilterSider] = useState(false);
	const [clickRangePicker, setClickRangePicker] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false)
	const [atRiskTab, setAtRiskTab] = useState(false); // It determines the tabs
	const [breadcrumb, setbreadcrumb] = useState(pathNameCandidates ? [{ link: '/recruiter-candidates', label: 'Candidates' }, { link: '/recruiter-candidates', label: 'Leads to Follow Up With' }] : [{ link: '/', label: 'Home' }, { link: '/recruiter-candidates', label: 'Leads to Follow Up With' }])
	const [clientStatus, setClientStatus] = useState([]);
	const [clientSelected, setClientSelcted] = useState([])
	const [statusesSelected, setStatusesSelected] = useState([]);
	const [tagsSelected, setTagsSelected] = useState([...CONSTANTS.RECRUITER_DASHBOARD_CALL_STATUS_TAGS]);
	const [callTypeFilter, setCallTypeFilter] = useState([...CONSTANTS.RECRUITER_DASHBOARD_CALL_TYPE_TAGS]);
	const [milestoneFilter, setMilestoneFilter] = useState([...CONSTANTS.RECRUITER_DASHBOARD_MILESTONE_TAGS]);
	const [candidateStatusFilter, setCandidateStatusFilter] = useState([...CONSTANTS.RECRUITER_DASHBOARD_CANDIDATE_STATUS_TAGS]);
	const dateFormat = 'YYYY/MM/DD';
	const dateFormatList = ['DD-MM-YYYY', 'DD/MM/YY', 'DD/MM/YYYY', 'DD-MM-YY'];
	// const [createMoengageUser, trackEvent] = useMoengage();
	const [filterApplied, setFilterApplied] = useState(false)
	const [totalApplicationCount, setTotalApplicationCount] = useState(0)
	const [currentPage, setCurrentPage] = useState(1)
	const [pageLimit, setPageLimit] = useState(50)
	const [searchText, setSearchText] = useState('')
	const [downloadLoading, setDownloadLoading] = useState(false)
	const [pageTypeforLeadReassignment, setPageTypeforLeadReassignment] = useState('follow_up')
	const [mitraList, setMitraList] = useState([])
	const [locationList, setLocationList] = useState([])
	const [selectedLeadTab, setselectedLeadTab] = useState(0)
	const [searchLocationList, setSearchLocationList] = useState([]);
	const [categoryList, setCategoryList] = useState([]);
	const [selectedSearchLocation, setSelectedSearchLocation] = useState([]);
	const [selectedScheduledInterviewId, setSelectedScheduledInterviewId] = useState([]);
	const [selectedRecommendationHistoryId, setSelectedRecommendationHistoryId] = useState([]);
	const [viewDetailLead, setViewDetailLead] = useState({});
	const [raiseIssueSider, setRaiseIssueSider] = useState(false);
	const [showVerifyEmailPopup, setShowVerifyEmailPopup] = useState(false);
	const [changeEmail, setChangeEmail] = useState(false);
	const [showHotLeadsModal, setShowHotLeadsModal] = useState(false);
	const [mitraEligibleForHotLeads, setMitraEligibleForHotLeads] = useState(false);
	const [lockHotLeads, setLockHotLeads] = useState(false);
	const [showHighIntentLeads, setShowHighIntentLeads] = useState(false);
	const [cancelToken, setCancelToken] = useState(null); // State to store the cancel token
	const [disableDatesBefore, setDisableDatesBefore] = useState(moment());

	const [hasDownloadAccess, setHasDownloadAccess] = useState(false);
	const [hasReassignAccess, setHasReassignAccess] = useState(false);
	const [showLeadReassignDialog, setShowLeadReassignDialog] = useState(false);
	const [allFiltersList, setAllFiltersList] = useState({});
	const [showChatbot, setShowChatbox] = useState(false);
	const [conversationData, setConversationData] = useState({
    conversationId: null,
    recommendationHistoryId: null,
  });
	const key = `open${moment()}`;
	const placement = 'topRight';


	useEffect(() => {
		if (mitraReducer && mitraReducer.allowedFeatures) {
			setHasDownloadAccess(mitraReducer?.allowedFeatures?.[CONSTANTS.DOWNLOAD_DATA_ACCESS]?.isEnabledOverall)
			setHasReassignAccess(mitraReducer?.allowedFeatures?.[CONSTANTS.CAN_REASSIGN_LEADS]?.isEnabledOverall)
		}
	}, [mitraReducer])

	useEffect(() => {
		if (mitraReducer && mitraReducer.allowedFeatures) {
			let viewAccessRestricted = mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.isEnable
			if (viewAccessRestricted) {
				setDisableDatesBefore(moment().subtract(mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.numberOfDays, 'days'))
			}
		}
	}, [mitraReducer])

	useEffect(() => {
		const data = {
			mitraId: mitraReducer?.mitraInfo?.id
		}
		let zomatoRequiredMilestoneKeys = ["p3AppDownloadedButNoSectionFilled", "p2Only1or2SectionsFilled", "p1Upto3or4SectionsFilled", "p0Upto5SectionsFilled", "obFeesPaid"]
		if (mitraReducer?.mitraInfo && !mitra) {
			showHotLeadsPopUpBackend(data).then(result => {
				if (result && result.data && result.data.response) {
					if (result?.data?.response?.show && result?.data?.response?.eligibility) {
						if (result?.data?.response?.show) {
							trackEvent('hot-leads-popup-shown', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
						}
						setShowHotLeadsModal(result.data.response.show);
					}
					if (result.data.response.eligibility) {
						setMitraEligibleForHotLeads(result.data.response.eligibility);
						setLockHotLeads(result.data.response.locked);
					}
				}
			})

			setIsAdmin(mitraReducer?.mitraInfo?.role === 'admin' ? true : false)
			setMitra(mitraReducer?.mitraInfo ? mitraReducer?.mitraInfo : {})
			getActiveClientsForFilter().then(data => {
				setClientSelcted(data.data.activeClients)
			})
			getStatusListForFilters().then(data => {
				let filteredStatuses = data.data.statuses.filter(status => {
					return !zomatoRequiredMilestoneKeys.includes(status.key);
				});
				setStatusesSelected(filteredStatuses)
			})
			getMitraTeamMembersList().then(response => {
				setMitraList(response.data.data);
			});
			getLocationForFilter().then(response => {
				setLocationList(response.data.locationList)
			})
			recruiterDashboardCandidateList(apiRequestPayload()).then(response => {
				setLeads(response.data.candidateList);
				if (response.data.count && Number(response.data.count) > 0) {
					setTotalApplicationCount(Number(selectedLeadTab === 1 ? response.data.hotLeads : selectedLeadTab === 2 ? response.data.coldLeads : selectedLeadTab === 3 ? response.data.recentlyFollowedUpCount : response.data.count))
				}
				setLoaded(true)
				console.log('response.data.candidateList', response.data.candidateList)
				setCategoryList({
					coldLeads: response.data.coldLeads,
					count: response.data.count,
					hotLeads: response.data.hotLeads,
					recentlyFollowedUpCount: response.data.recentlyFollowedUpCount
				})
			})

		}
	}, [mitraReducer])

	const apiRequestPayload = (email = null) => {
		let clientList = [], statusList = [], teamMemberList = [],
			selectLocationList = [], callStatusTagList = [], callTypeTagList = [], milestoneTagsList = [], candidateStatusList = [];

		tagsSelected.map(client => {
			if (client.value) {
				if (client.key === 'connected' || client.key === 'notConnected') callStatusTagList.push(client.key)
				else callStatusTagList.push(client.label)
			}
		});

		callTypeFilter.map(client => {
			if (client.value) {
				callTypeTagList.push(client.key)
			}
		});

		milestoneFilter.map(client => {
			if (client.value) {
				milestoneTagsList.push(client.key)
			}
		});

		candidateStatusFilter.map(client => {
			if (client.value) {
				candidateStatusList.push(client.key)
			}
		});

		clientSelected.map(client => {
			if (client.value) {
				clientList.push(client.label.toLowerCase())
			}
		})

		statusesSelected.map(status => {
			if (status.value) {
				statusList.push(status.key)
			}
		})

		mitraList.map(status => {
			if (status.value) {
				teamMemberList.push(status.id)
			}
		})

		locationList.map(status => {
			if (status.value) {
				selectLocationList.push(status.label)
			}
		})
		let data = {
			startDate: moment(startDate).format('YYYY-MM-DD'),
			endDate: moment(endDate).format('YYYY-MM-DD'),
			clients: JSON.stringify(clientList),
			statuses: JSON.stringify(statusList),
			callStatuses: JSON.stringify(callStatusTagList),
			callType: JSON.stringify(callTypeTagList),
			milestones: JSON.stringify(milestoneTagsList),
			candidateStatus: JSON.stringify(candidateStatusList),
			teamMemberIds: JSON.stringify(teamMemberList),
			locations: JSON.stringify(selectLocationList),
			offset: currentPage * pageLimit - pageLimit,
			limit: pageLimit,
			candidateType: selectedLeadTab === 1 ? 'hotLeads' : selectedLeadTab === 2 ? 'coldLeads' : selectedLeadTab === 3 ? 'recentlyFollowedUp' : '',
			searchText: searchText,
			email,
			changeEmail,
			highIntentCheck: showHighIntentLeads
		}
		createFiltersListForLeadReassignment(clientList, statusList, callStatusTagList, teamMemberList, selectLocationList, milestoneTagsList)
		return data;
	}

	useEffect(() => {
		if (mitra?.id && filterApplied) {
			setLoaded(false)
			getCandidatesList()
			setFilterApplied(false)
			// setCurrentPage(1)
			// const t0 = performance.now();
			// if(!atRiskTab){
			// 	getCandidatesData(data).then((result) => {
			// 		setLoaded(true)
			// 		setLeads(result.data.result)
			// 		if(result.data.count && Number(result.data.count) > 0){
			// 			setTotalApplicationCount(Number(result.data.count))
			// 		}
			// 		setFilterApplied(false)
			// 		const t1 = performance.now();
			// 		console.log(`Api getCandidatesData call took ${t1 - t0} milliseconds.`);
			// 		trackEvent('candidates_page_load_time', {
			// 			candidates_page_load_time:(t1 - t0)
			// 		},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			// })
			// }
			// else {
			// 	data = {
			// 		offset: currentPage*pageLimit - pageLimit,
			// 		limit: pageLimit,
			// 		search: searchText
			// 	}
			// 	getAtRiskApplications(data).then(result => {
			// 		console.log(result)
			// 		setLoaded(true)
			// 		setLeads(result.data.result)
			// 		setTotalApplicationCount(Number(result.data.count))
			// 		setFilterApplied(false)
			// 		const t1 = performance.now();
			// 		console.log(`Api getAtRiskApplications call took ${t1 - t0} milliseconds.`);
			// 		trackEvent('candidates_page_load_time', {
			// 			candidates_page_load_time:(t1 - t0)
			// 		},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			// 	})
			// }

		}

	}, [mitra, filterApplied])

	useEffect(() => {
		// incoming calls notification reset
		updateNotifiedIncomingCalls().then(response => {
			console.log(response);
		}).catch(error => {
			console.log(error);
		});

		trackEvent('viewed_follow_up_leads', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		const startTime = performance.now();

		return () => { // clean-up function to get the time spent on the recruiter dash
			trackEvent('recruiter_dashboard_duration', { recruiter_dashboard_duration: performance.now() - startTime }, CONSTANTS.ENGAGEMENT_TYPES.MOENGAGE);
		}
	}, [])

	const getCandidatesList = () => {
		if (cancelToken) {
			cancelToken.cancel(); // Used to cancel previous request to prevent data discrepancy
		}
		const newCancelToken = axios.CancelToken.source(); // Create a new CancelToken
		setCancelToken(newCancelToken); // Set the new cancel token

		recruiterDashboardCandidateList(apiRequestPayload(), newCancelToken.token).then(response => {
			setLeads(response.data.candidateList);
			if (response.data.count && Number(response.data.count) > 0) {
				setTotalApplicationCount(Number(selectedLeadTab === 1 ? response.data.hotLeads : selectedLeadTab === 2 ? response.data.coldLeads : selectedLeadTab === 3 ? response.data.recentlyFollowedUpCount : response.data.count))
			}
			else {
				setTotalApplicationCount(0);
			}
			setLoaded(true)
			setCategoryList({
				coldLeads: response.data.coldLeads,
				count: response.data.count,
				hotLeads: response.data.hotLeads,
				recentlyFollowedUpCount: response.data.recentlyFollowedUpCount
			})
		})
	}

	const createFiltersListForLeadReassignment = (clientList = [], statusList = [], callStatusTagList = [], teamMemberList = [], selectLocationList = [], milestoneTagsList) => {
		let data = {
			startDate: moment(startDate).format("YYYY-MM-DD"),
			endDate: moment(endDate).format("YYYY-MM-DD"),
			clients: clientList,
			statuses: statusList,
			callStatusTagList: callStatusTagList,
			teamMemberIds: teamMemberList,
			locations: selectLocationList,
			milestones: milestoneTagsList
		}
		setAllFiltersList(data)
		console.log("ALL_FILTERS_FOR_LEAD_REASSIGNMENT", data)
	}

	const deleteChip = (name, item) => {
		if (name === 'status') {
			let newStatus = [...statusesSelected]
			newStatus.map((single_status, index) => {
				if (single_status.label === item.label) {
					single_status.value = false;
				}
			})
			setStatusesSelected(newStatus)
		}
		else if (name === 'callType') {
			let newCallType = [...callTypeFilter]
			newCallType.map((single_status, index) => {
				if (single_status.label === item.label) {
					single_status.value = false;
				}
			})
			setCallTypeFilter(newCallType)
		}
		else if (name === 'milestone') {
			let newMilestones = [...milestoneFilter]
			newMilestones.map((single_status, index) => {
				if (single_status.label === item.label) {
					single_status.value = false;
				}
			})
			setMilestoneFilter(newMilestones)
		}
		else if (name === 'candidateStatus') {
			let newcandidateStatuses = [...candidateStatusFilter]
			newcandidateStatuses.map((single_status, index) => {
				if (single_status.label === item.label) {
					single_status.value = false;
				}
			})
			setCandidateStatusFilter(newcandidateStatuses)
		}
		else if (name === 'category') {
			let newCategory = [...clientSelected]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setClientSelcted(newCategory)
		}
		else if (name === 'team_member') {
			let newCategory = [...mitraList]
			newCategory.map((single_category, index) => {
				if (single_category.name === item.name) {
					single_category.value = false
				}
			})
			setMitraList(newCategory)
		} else if (name === 'location') {
			let newCategory = [...locationList]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setLocationList(newCategory)
		} else if (name === 'call_status') {
			let newCategory = [...tagsSelected]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setTagsSelected(newCategory)
		}
		else if (name === 'date') {
			if (!atRiskTab) {
				setStartDate(getfirstDateOfMonth())
				setEndDate(moment())
			}
			else {
				setStartDate(moment().subtract(13, 'd').format('YYYY-MM-DD'));
				setEndDate(moment().subtract(7, 'd'))
			}
		}
		setFilterApplied(true)
		setCurrentPage(1)
	}

	const handleSheet = async (email) => {
		setDownloadLoading(true)
		recruiterDashboardCandidateListDownload(apiRequestPayload(email)).then((response) => {
			trackEvent('downloaded_follow_up_leads', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
			if (response.data.responseCandidatesUrl) {
				console.log(response)
				window.open(response.data.responseCandidatesUrl, '_blank')
			}
			else {
				setAlert({
					display: true,
					text: response.data.message,
					type: response.data.message.toLowerCase().includes('no') ? 'error' : 'success'
				});
			}
			setDownloadLoading(false)
		})
	}

	const handlePicker = () => {
		setClickRangePicker(!clickRangePicker);
	}

	const handleDateRange = (dates, dateStrings) => {
		setClickRangePicker(!clickRangePicker);
		if (dates === null) {
			setStartDate(moment().subtract(30, 'days').format('YYYY-MM-DD'));
			setEndDate(moment());
		}
		else {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	}

	const clearAll = () => {
		clientSelected.map(item => {
			item.value = false;
		});

		statusesSelected.map(item => {
			item.value = false;
		});

		mitraList.map(item => {
			item.value = false;
		});

		locationList.map(item => {
			item.value = false;
		});

		tagsSelected.map(item => {
			item.value = false;
		});

		callTypeFilter.map(item => {
			item.value = false;
		});

		candidateStatusFilter.map(item => {
			item.value = false;
		});

		milestoneFilter.map(item => {
			item.value = false;
		});
		setStartDate(moment().subtract(30, 'days').format('YYYY-MM-DD'));
		setEndDate(moment());
		setFilterApplied(true)
		// setfilterSider(false)
		setselectedLeadTab(0)
	}

	const handleTabChange = (label) => {
		if (label === 0) {
			setTotalApplicationCount(categoryList?.count)
		} else if (label === 1) {
			setTotalApplicationCount(categoryList?.hotLeads)
		} else if (label === 2) {
			setTotalApplicationCount(categoryList?.coldLeads)
		} else if (label === 3) {
			setTotalApplicationCount(categoryList?.recentlyFollowedUpCount)
		} else {
			return
		}
		setselectedLeadTab(label);
		setFilterApplied(true)
		setCurrentPage(1)
	}

	const onClickTags = (label, index) => {
		if (label === 'Status') {
			statusesSelected[index].value = !statusesSelected[index].value;
		}
		if (label === 'Candidate Status') {
			candidateStatusFilter[index].value = !candidateStatusFilter[index].value;
		}
		if (label === 'Clients') {
			clientSelected[index].value = !clientSelected[index].value;
		}
		if (label === 'Select Team Member') {
			mitraList[index].value = !mitraList[index].value;
		}
		if (label === 'Location') {
			locationList[index].value = !locationList[index].value;
		}
		if (label === 'Call Status') {
			tagsSelected[index].value = !tagsSelected[index].value;
		}
		if (label === 'Call Type') {
			callTypeFilter[index].value = !callTypeFilter[index].value;
		}
		if (label === 'Milestones') {
			milestoneFilter[index].value = !milestoneFilter[index].value;
		}
		forceUpdate()
	}

	const preventDefault = (e, label, item) => {
		e.preventDefault()
		deleteChip(label, item)
		console.log('Clicked! But prevent default.');
	}

	const applyFilterCondition = () => {
		let result = false;
		let arr = [statusesSelected, clientSelected, endDate];
		arr.map(item => {
			if (item) {
				for (let i = 0; i < item.length; i++) {
					if (item[i].value === true) {
						result = true;
						break;
					}
				}
			}
			if (moment(endDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
				result = true;
			}
		})
		return result;
	}
	const submitFilter = () => {
		setFilterApplied(true)
		setCurrentPage(1)
	}

	const handleChangeSelectLoction = (valueArr) => {
		let arrObj = [];
		if (valueArr.length === 0) {
			arrObj = []
		} else {
			valueArr.map(item => {
				arrObj.push({ label: item, value: true })
			})
			let result = [...arrObj, ...locationList];
			setLocationList(commonFunc.uniquefilteredArrObj(result, "label"))
		}
	}

	const fetchDisabledDates = (current) => {
		//Disable all dates before allowed number of days mentioned in View Data Access 
		return current && current < disableDatesBefore.endOf("day");
	}

	const commonTag = (label, arrObj) => {
		return <div className='tag-containers'>
			<div>{label}</div>
			{label === "Date Range" ?
				<Space>
					<RangePicker
						onClick={handlePicker}
						open={clickRangePicker}
						defaultValue={[moment(startDate, dateFormatList[0]), moment(endDate, dateFormatList[0])]}
						value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
						format={dateFormatList}
						onChange={handleDateRange}
						ranges={{
							Today: [moment().startOf('day'), moment().endOf('day')],
							'This Month': [moment().startOf('month'), moment().endOf('month')],
							'Last 7 days': [moment().startOf('day').subtract(6, 'days'), moment().endOf('day')],
							'Last 10 days': [moment().startOf('day').subtract(9, 'days'), moment().endOf('day')],
						}}
						disabledDate={false}
						allowClear={false}
						className="range-picker-leads"
					/>
				</Space> :
				label === "Location" ?
					<>
						<Select
							mode="multiple"
							style={{
								width: '100%',
							}}
							placeholder="Search Location(s)"
							onChange={handleChangeSelectLoction}
							onSearch={(searchValue) => {
								let data = {
									search: searchValue
								}
								if (searchValue.length >= 2) {
									getLocationForFilter(data).then(response => {
										setSearchLocationList(response.data.locationList)
									})
								}
							}}
							allowClear
						>
							{searchLocationList.map(item => (
								<Option key={`option_location_search_${item}`} value={item}>
								</Option>
							))}
						</Select>
						<div className='tag-wrapper-container'>
							{arrObj.map((item, index) => {
								return <Tag className={!(item.value) ? 'tags' : 'tags tag-active'}
									key={index}
									// closable={item.value}
									onClick={() => { onClickTags(label, index) }}
								>
									{item.label || item.name}
								</Tag>
							})}
						</div>
					</>
					:
					<div className='tag-wrapper-container'>
						{arrObj.map((item, index) => {
							return <Tag className={!(item.value) ? 'tags' : 'tags tag-active'}
								key={index}
								// closable={item.value}
								onClick={() => { onClickTags(label, index) }}
							>
								{item.label || item.name}
							</Tag>
						})}
					</div>
			}

		</div>
	}

	const onFilterClick = () => {
		setfilterSider(true)
		setClickRangePicker(true)
		trackEvent('clicked_filters_follow_ups', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	};

	const openFilterSlider = () => {
		setClickRangePicker(false);
		setfilterSider(true)
		trackEvent('clicked_filters_follow_ups', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	};

	const searchCandidate = (e) => {
		setSearchText(e)
		setFilterApplied(true)
		setCurrentPage(1)
	}

	const handleDownload = () => {
		setShowVerifyEmailPopup(true)
	}

	const clickHotLeadsModal = () => {
		const data = {
			mitraId: mitraReducer?.mitraInfo?.id,
			clicked: true
		}
		showHotLeadsPopUpBackend(data).then(result => {
			if (result && result.data && result.data.response) {
				if (result?.data?.response?.show && result?.data?.response?.eligibility) {
					setShowHotLeadsModal(result.data.response.show);
				}
				if (result.data.response.eligibility) {
					setMitraEligibleForHotLeads(result.data.response.eligibility);
					setLockHotLeads(result.data.response.locked);
				}
			}
		})
	}

	const handlePageChangeSelection = (value) => {
		trackEvent('candidates_page_tab_switch', { tabName: value }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);

		if (value === 'all') {
			history.push({ pathname: '/leads/all-candidates' })
		} else if (value === 'preReferralLeads') {
			history.push({ pathname: '/pre-referral-leads-candidates' })
		} else if (value === 'leadsToFollowUp') {
			history.push({ pathname: '/recruiter-candidates' })
		} else if (value === 'hotLeads') {
			if (lockHotLeads) {
				clickHotLeadsModal();
			} else {
				history.push({ pathname: '/hot-leads-candidates' })
			}
		}
	}

	const onChangeShowHighIntentLeads = (checked) => {
		if (checked) {
			trackEvent('high_leads_toggle_on', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		}
		setShowHighIntentLeads(checked);
		setFilterApplied(true)
	};

	const onReassignLeadsClicked = () => {
		if (allFiltersList?.teamMemberIds?.length == 0) {
			notification.open({
				message: "Please select at least one team member from filters to proceed",
				key,
				duration: 5,
				placement,
				icon: <ExclamationCircleOutlined className='red-negative' />,
			});
		}
		else if (!loaded) {
			notification.open({
				message: "Please wait while candidates are loading.",
				key,
				duration: 5,
				placement,
				icon: <ExclamationCircleOutlined className='red-negative' />,
			});
		}
		else {
			trackEvent('opened_reassignment_dialog_from_listing', {
				page: "leads_to_follow_up_with",
			}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			setShowLeadReassignDialog(true)
		}
	}

	// console.log(totalApplicationCount);
	return (
		<>
			<HotLeadsModal
				showModal={showHotLeadsModal}
				setShowHotLeadsModal={setShowHotLeadsModal}
				mitraId={mitraReducer?.mitraInfo?.id}
			></HotLeadsModal>
			{
				showLeadReassignDialog &&
				<Modal
					key="leadReassignDialog"
					title={null}
					centered
					visible={showLeadReassignDialog}
					open={showLeadReassignDialog}
					footer={null}
					width={600}
					height={700}
					className='reassign-leads-dialog'
					onCancel={() => { setShowLeadReassignDialog(false) }}
				>
					<ReassignLeadsDialog closeModal={() => { setShowLeadReassignDialog(false) }} showHeader={true} totalFilteredCandidates={totalApplicationCount} allFiltersFromParent={allFiltersList} allTeamMembersList={mitraList} isBulkReassignment={true} pageType={pageTypeforLeadReassignment} isLeadReassigned={getCandidatesList} />
				</Modal>
			}
			<div className="LeadPage Recruiter mainContentZ">
				<div className="header">
					<PageHeaderComponent breadcrumb={breadcrumb} backBtnBool={true} />
				</div>
				<div style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row' }}>
					<div className='page-filter-options show-only-desktop'>
						{mitraEligibleForHotLeads ?
							<Button onClick={(e) => handlePageChangeSelection('hotLeads')} style={{ display: 'flex', alignItems: 'center', borderRight: 'none', borderRadius: 0 }}>
								{lockHotLeads ? <LockOutlined /> : <FireOutlined />}Hot Leads
							</Button> : null}
						<Button onClick={(e) => handlePageChangeSelection('preReferralLeads')} style={{ borderRadius: 0 }}>
							Pre-Referral Leads
						</Button>
						<Button onClick={(e) => handlePageChangeSelection('leadsToFollowUp')} style={{ borderRadius: 0 }} type="primary" ghost>
							Leads to Follow Up With
						</Button>
						{/* <Button onClick={(e) => handlePageChangeSelection('actionRequired')} style={{ borderRadius: 0 }}>
							Action required
						</Button> */}
            <Button onClick={(e) => handlePageChangeSelection('all')} style={{ borderRadius: 0 }}>
              All
            </Button>
          </div>

          <div
            style={{
              width: '40%',
              alignItems: 'flex-end',
              margin: '2%',
              display: 'flex',
              justifyContent: 'right',
            }}
          >
            <SearchComponent placeholder={'Search Candidates'} searchItem={searchCandidate} />
          </div>
          {/* <Button onClick={()=>{
								history.push("/search-page")
							}} style={{color:'var(--primary-theme-color)',display:'flex',alignItems:'center'}} icon={<SearchOutlined />}  danger ghost size={'large'} >Search</Button> */}
        </div>
        {/* display: 'flex', alignItems: 'center' */}
        <Select
          className="show-only-mobile"
          style={{
            margin: '2%',
            width: '60%',
            textAlign: 'left',
            color: 'var(--primary-theme-color)',
          }}
          onChange={handlePageChangeSelection}
          defaultValue="leadsToFollowUp"
        >
          <Select.Option
            style={{
              textAlign: 'left',
              color: 'var(--primary-theme-color)',
              backgroundColor: '#FFF',
            }}
            value="leadsToFollowUp"
          >
            Leads to Follow Up With
          </Select.Option>
          <Select.Option style={{ textAlign: 'left' }} value="preReferralLeads">
            Pre-Referral Leads
          </Select.Option>
          {mitraEligibleForHotLeads ? (
            <Select.Option
              className="hot-leads-select"
              style={{ textAlign: 'left' }}
              value="hotLeads"
            >
              {lockHotLeads ? (
                <LockOutlined style={{ marginRight: '2%' }} />
              ) : (
                <FireOutlined style={{ marginRight: '2%' }} />
              )}
              Hot Leads
            </Select.Option>
          ) : null}
          <Select.Option style={{ textAlign: 'left' }} value="all">
            All
          </Select.Option>
        </Select>
        {/* </div> */}
        <div className="more-filters">
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1%' }}>
            <Space>
              <RangePicker
                open={false}
                defaultValue={[
                  moment(startDate, dateFormatList[0]),
                  moment(endDate, dateFormatList[0]),
                ]}
                value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                format={dateFormatList}
                onClick={onFilterClick}
                allowClear={false}
                className="range-picker-leads"
                disabledDate={fetchDisabledDates}
              />
            </Space>
            {CONSTANTS.highIntentEligibleMitras.includes(mitraReducer?.mitraInfo?.id) ||
            CONSTANTS.highIntentEligibleMitras.includes(mitraReducer?.mitraInfo?.managerMitraID) ? (
              <>
                <div className="high-intent">
                  <ThunderboltFilled style={{ marginLeft: 16, marginRight: 6 }} />
                  High Intent
                  <Switch
                    className="high-intent-toggle-switch"
                    onChange={onChangeShowHighIntentLeads}
                  />
                </div>
                <div className="high-intent-info-icon">
                  <InfoCircleOutlined />
                </div>
              </>
            ) : null}
          </div>
          <div className="filter-set-container">
            <Button
              disabled={atRiskTab}
              style={
                !atRiskTab && applyFilterCondition()
                  ? {
                      display: 'flex',
                      alignItems: 'center',
                      color: 'var(--primary-theme-color)',
                      backgroundColor: 'var(--primary-theme-light)',
                      border: '1px solid var(--primary-theme-color)',
                    }
                  : {
                      display: 'flex',
                      alignItems: 'center',
                      color: 'var(--primary-theme-color)',
                      border: '1px solid var(--primary-theme-color)',
                    }
              }
              onClick={openFilterSlider}
              icon={<FilterOutlined />}
            >
              {' '}
              Filter
            </Button>
            <div className="desktop-pagination" style={{}}>
              {leads.length > 0 && totalApplicationCount > 0 ? (
                <Pagination
                  total={totalApplicationCount > 0 && totalApplicationCount}
                  showTotal={(total, range) =>
                    `${currentPage == 1 ? 1 : range[0]}-${range[1]} of ${total} items`
                  }
                  defaultPageSize={pageLimit}
                  defaultCurrent={currentPage}
                  responsive={true}
                  showLessItems={true}
                  onChange={(page, pagesize) => {
                    setCurrentPage(page);
                    setFilterApplied(true);
                  }}
                  current={currentPage}
                  // hideOnSinglePage={true}
                  showSizeChanger={false}
                />
              ) : null}
            </div>
          </div>
          <div className="Chips-container">
            {clientSelected?.map((single_category, index) => {
              if (single_category.value) {
                return (
                  <Tag
                    key={index}
                    className="single-chip"
                    closable
                    onClose={(e) => preventDefault(e, 'category', single_category)}
                  >
                    {single_category.label}{' '}
                  </Tag>
                );
              }
            })}

            {statusesSelected?.map((single_status, index) => {
              if (single_status.value) {
                return (
                  <Tag
                    key={index}
                    className="single-chip"
                    closable
                    onClose={(e) => preventDefault(e, 'status', single_status)}
                  >
                    {single_status.label}{' '}
                  </Tag>
                );
              }
            })}
            {callTypeFilter?.map((single_status, index) => {
              if (single_status.value) {
                return (
                  <Tag
                    key={index}
                    className="single-chip"
                    closable
                    onClose={(e) => preventDefault(e, 'callType', single_status)}
                  >
                    {single_status.label}{' '}
                  </Tag>
                );
              }
            })}
            {milestoneFilter?.map((single_status, index) => {
              if (single_status.value) {
                return (
                  <Tag
                    key={index}
                    className="single-chip"
                    closable
                    onClose={(e) => preventDefault(e, 'milestone', single_status)}
                  >
                    {single_status.label}{' '}
                  </Tag>
                );
              }
            })}
            {candidateStatusFilter?.map((single_status, index) => {
              if (single_status.value) {
                return (
                  <Tag
                    key={index}
                    className="single-chip"
                    closable
                    onClose={(e) => preventDefault(e, 'candidateStatus', single_status)}
                  >
                    {single_status.label}{' '}
                  </Tag>
                );
              }
            })}
            {mitraList?.map((single_status, index) => {
              if (single_status.value) {
                return (
                  <Tag
                    key={index}
                    className="single-chip"
                    closable
                    onClose={(e) => preventDefault(e, 'team_member', single_status)}
                  >
                    {single_status.name}{' '}
                  </Tag>
                );
              }
            })}
            {tagsSelected?.map((single_status, index) => {
              if (single_status.value) {
                return (
                  <Tag
                    key={index}
                    className="single-chip"
                    closable
                    onClose={(e) => preventDefault(e, 'call_status', single_status)}
                  >
                    {single_status.label}{' '}
                  </Tag>
                );
              }
            })}
            {[...new Set(locationList)]?.map((single_status, index) => {
              if (single_status.value) {
                return (
                  <Tag
                    key={index}
                    className="single-chip"
                    closable
                    onClose={(e) => preventDefault(e, 'location', single_status)}
                  >
                    {single_status.label}{' '}
                  </Tag>
                );
              }
            })}
          </div>
        </div>
        <div className="download-plus">
          {
            <div style={{ width: '100%', textAlign: 'end' }}>
              {isAdmin && (
                <Button
                  danger
                  loading={downloadLoading}
                  style={{ margin: '5px 20px 5px auto' }}
                  onClick={() => {
                    trackEvent(
                      'on_click_tc_dashboard',
                      {},
                      CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS
                    );
                    history.push({
                      pathname: '/telecallerDashboard',
                    });
                  }}
                >
                  Go To TC Dashboard
                </Button>
              )}
              {hasReassignAccess && totalApplicationCount > 0 ? (
                // disabled={allFiltersList?.teamMemberIds?.length > 0}
                <Button
                  style={{ margin: '5px 20px 5px auto' }}
                  onClick={onReassignLeadsClicked}
                  className={`${allFiltersList?.teamMemberIds?.length == 0 ? 'opacity04' : ''}`}
                >
                  Re-assign
                </Button>
              ) : null}
              {hasDownloadAccess ? (
                <Button
                  danger
                  loading={downloadLoading}
                  style={{ margin: '5px 20px 5px auto' }}
                  onClick={() => {
                    handleDownload();
                  }}
                >
                  Download File
                </Button>
              ) : null}
            </div>
          }
          {
            <div className="leads-chips">
              <Text style={{ fontSize: 14, color: '#000000D9', fontWeight: 400 }} strong>
                {categoryList.count}&nbsp;Candidates
              </Text>
              {/* <Tag className='single-chip' onClick={()=>{handleTabChange(1)}} style={{color:selectedLeadTab === 1 ? "var(--primary-theme-color)" :null,border:selectedLeadTab === 1 ? "1px solid var(--primary-theme-color)":null}}>Hot Leads <Text style={{fontSize:11}} strong>{categoryList.hotLeads}</Text></Tag>
							<Tag className='single-chip' onClick={()=>{handleTabChange(2)}} style={{color:selectedLeadTab === 2 ? "#2F54EB" :null,border:selectedLeadTab === 2 ? "1px solid #2F54EB":null}}>Cold Leads <Text style={{fontSize:11}} strong>{categoryList.coldLeads}</Text></Tag> */}
              {mitraReducer?.mitraInfo && rollOutCallHistory(mitraReducer?.mitraInfo) ? (
                <Tag
                  className="single-chip"
                  onClick={() => {
                    handleTabChange(3);
                  }}
                  style={{
                    color: selectedLeadTab === 3 ? '#2F54EB' : null,
                    border: selectedLeadTab === 3 ? '1px solid #2F54EB' : null,
                  }}
                >
                  Recently Followed Up
                  <Text style={{ fontSize: 11 }} strong>
                    {' '}
                    {`  ${categoryList.recentlyFollowedUpCount}`}
                  </Text>
                </Tag>
              ) : null}
            </div>
          }
        </div>
        {/* <div className='banner-copy-tag'>
					{selectedLeadTab !== 2 &&<Tag color="error" icon={<InfoCircleOutlined />}>
						{selectedLeadTab === 0 && <Text>Hot Lead: Leads have changed status recently.</Text>}
						{selectedLeadTab === 1 && <Text>Hot Lead: Leads whose status recently in the last 7 days</Text>}
					</Tag>}
					{selectedLeadTab !==1 && <Tag color="#F0F5FF" style={{color:'#2F54EB'}} icon={<InfoCircleOutlined />}>
						<Text>Cold Leads: Leads whose status changed status &gt; 7 days ago.</Text>
					</Tag>}
				</div> */}
        <div className="leads-wrapper">
          {loaded ? (
            leads.length === 0 ? (
              <div style={{ margin: '20px auto', height: '68vh' }}>
                <Empty
                  description={
                    <span>
                      No active candidates in the date range,
                      <button
                        onClick={() => {
                          mitraDispatch({ type: 'UTILITIES', value: { currentMenu: 'LEADS' } });
                          history.push(
                            `/leads/all-candidates?startDate=${startDate}&&endDate=${endDate}`
                          );
                        }}
                        style={{ textDecoration: 'underline', color: 'red', fontWeight: '600' }}
                      >
                        See referred candidates
                      </button>
                    </span>
                  }
                />
              </div>
            ) : (
              leads.map((lead, index) => (
                <RecruiterCard
                  key={index}
                  lead={lead}
                  setShowDetail={setShowDetail}
                  setSelectedLead={setSelectedLead}
                  setSelectedScheduledInterviewId={setSelectedScheduledInterviewId}
                  setSelectedRecommendationHistoryId={setSelectedRecommendationHistoryId}
                  atRiskTab={atRiskTab}
                  setSpin={setSpin}
                  mitra={mitra}
                />
              ))
            )
          ) : (
            <div style={{ height: '65vh', width: '100%' }}>
              <NewLoader comingFromPage={'leadsPage'} />
            </div>
          )}
          <br />
          <div className="mobile-pagination" style={{}}>
            {leads.length > 0 && totalApplicationCount > 0 ? (
              <Pagination
                total={totalApplicationCount > 0 && totalApplicationCount}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                defaultPageSize={pageLimit}
                defaultCurrent={currentPage}
                responsive={true}
                showLessItems={true}
                onChange={(page, pagesize) => {
                  setCurrentPage(page);
                  setFilterApplied(true);
                }}
                // hideOnSinglePage={true}
                showSizeChanger={false}
              />
            ) : null}
          </div>
        </div>
        <div className="bottom-detail-wrapper">
          {showDetail && (
            <CandidateProfilePopupV2
              visible={showDetail}
              setShowDetail={setShowDetail}
              lead={selectedLead}
              selectedScheduledInterviewId={selectedScheduledInterviewId}
              selectedRecommendationHistoryId={selectedRecommendationHistoryId}
              statuses={clientStatus}
              source={'followUp'}
              setRaiseIssueSider={setRaiseIssueSider}
              setViewDetailLead={setViewDetailLead}
              reassignAccess={hasReassignAccess}
              leadReassignedViaPopup={getCandidatesList}
              page={'leads_to_follow_up'}
              setConversationData={setConversationData}
              setShowChatbox={setShowChatbox}
            ></CandidateProfilePopupV2>
          )}
        </div>
      </div>
      {filterSider && (
        <SideSheet
          submit={{
            disabled: false,
            onClick: submitFilter,
          }}
          clearAll={{ disabled: false, onClick: clearAll }}
          sidername={'Filter Candidates'}
          onDismiss={() => {
            setfilterSider(false);
          }}
        >
          <div className="filter-home-wrapper">
            {commonTag('Date Range', null)}
            {commonTag('Select Team Member', mitraList)}
            {commonTag('Call Status', tagsSelected)}
            {mitraReducer?.mitraInfo && rollOutCallHistory(mitraReducer?.mitraInfo)
              ? commonTag('Call Type', callTypeFilter)
              : null}
            {commonTag('Candidate Status', candidateStatusFilter)}
            {commonTag('Milestones', milestoneFilter)}
            {commonTag('Clients', clientSelected)}
            {commonTag('Location', locationList)}
          </div>
        </SideSheet>
      )}
      {(userRole === ROLES?.VAHAN_PRO_USER || userRole === ROLES?.SUPPORT_AUTOMATION_ACCESS_USER) &&
      showChatbot ? (
        <SupportChatBot
          setShowChatbox={setShowChatbox}
          conversationId={conversationData?.conversationId}
          source={'candidate_popup'}
          sourceRhId={conversationData?.recommendationHistoryId}
        />
      ) : (
        raiseIssueSider && (
          <RaiseIssueSider
            closeSider={() => setRaiseIssueSider(false)}
            mitraReducer={mitraReducer}
            selectedLead={viewDetailLead}
          />
        )
      )}
      {showVerifyEmailPopup && (
        <VerifyEmailPopup
          mitra={mitra}
          visible={showVerifyEmailPopup}
          closePopup={setShowVerifyEmailPopup}
          downloadFunction={handleSheet}
          changeEmail={changeEmail}
          setChangeEmail={setChangeEmail}
        ></VerifyEmailPopup>
      )}
      {showLeadReassignDialog && (
        <Modal
          key="leadReassignDialog"
          title={null}
          centered
          visible={showLeadReassignDialog}
          open={showLeadReassignDialog}
          footer={null}
          width={600}
          height={800}
          className="reassign-leads-dialog"
          onCancel={() => {
            setShowLeadReassignDialog(false);
          }}
        >
          <ReassignLeadsDialog
            closeModal={() => {
              setShowLeadReassignDialog(false);
            }}
            showHeader={true}
            totalFilteredCandidates={totalApplicationCount}
            allFiltersFromParent={allFiltersList}
            allTeamMembersList={mitraList}
            isBulkReassignment={true}
            pageType={pageTypeforLeadReassignment}
            isLeadReassigned={getCandidatesList}
          />
        </Modal>
      )}
    </>
  );
};

export default RecruiterPage;
