import React, { useContext, useEffect, useState } from 'react'
import './UniversalTopBar.scss';
import { Avatar, Button, Divider, Input, Modal, Popover, Switch } from 'antd';
import {UserOutlined} from '@ant-design/icons';
import SearchIcon from '../../static/svg/SearchIcon';
import BellIcon from '../../static/svg/BellIcon';
import { useHistory, useLocation } from 'react-router-dom';
import MenuIcon from '../../static/svg/MenuIcon';
import MobileSidebar from '../MobileSidebar/MobileSidebar';
import UserOctagon from '../../static/svg/UserOctagon';
import SettingsIcon from '../../static/svg/SettingsIcon';
import BookOpenIcon from '../../static/svg/BookOpenIcon';
import QuestionCircleIcon from '../../static/svg/QuestionCircle';
import LogoutIcon from '../../static/svg/LogoutIcon';
import * as loginService from '../../service/loginService';
import { AppContext } from '../../App';
import { getMenuKeyFromUrlPathname } from '../../constants/utils';
import { getCountOfUnreadSupportConversations } from '../../service/SupportV2';
import { ROLES } from '../common/roles';
import CONSTANTS from '../../constants/constants';
import { trackEvent } from '../../service/engagementMonitoringService';
import LeftArrow from '../../static/svg/LeftArrow';
import CrossIcon from '../../static/svg/CrossIcon';
import Branding from '../common/Branding';

function UniversalTopBar() {
    const [showSearchSection, setShowSearchSection] = useState(true);
    const history = useHistory();
    const location = useLocation();
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const {mitraReducer, mitraDispatch} = useContext(AppContext);
    const [openDropdownMenu, setOpenDropdownMenu] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);
    const [showVahanPro, setShowVahanPro] = useState(true);
    const [openChangeThemeModal, setOpenChangeThemeModal] = useState(false);
    let supportTicketNotificationInterval;

    const hideDropdownMenu = () => {
        setOpenDropdownMenu(false);
    };

    const handleOpenChange = (newOpen) => {
        setOpenDropdownMenu(newOpen);
    };

    const [dropdownMenu, setDropdownMenu] = useState([
        {
            menuLabel: 'Account Settings',
            menuKey: 'ACCOUNT',
            path: '/account',
            icon: <SettingsIcon height={16} width={16} strokeColor='#838383'/>,
            isActive: false,
            activeIcon: <SettingsIcon height={16} width={16} strokeColor='#FFF'/>
        },
        {
            menuLabel: 'FAQs',
            menuKey: 'FAQ',
            path: process.env.REACT_APP_FAQ_LINK,
            link: 'https://support-vahanpro.framer.website/',
            icon: <BookOpenIcon height={16} width={16} strokeColor='#838383'/>,
            isActive: false,
            activeIcon: <BookOpenIcon height={16} width={16} strokeColor='#FFF'/>
        },
        {
            menuLabel: 'Help',
            menuKey: 'HELP',
            path: '/help-lp-auto',
            icon: <QuestionCircleIcon height={16} width={16} strokeColor='#838383'/>,
            isActive: false,
            activeIcon: <QuestionCircleIcon height={16} width={16} strokeColor='#FFF'/> 
        }
    ]);

    const markActiveMenu = (location) => {
        if(location.pathname) {
			const menuKey = getMenuKeyFromUrlPathname(location.pathname);

			if(menuKey) {
                const clickedMenu = {
                    menuKey: menuKey
                };
    
                markMenuActive(clickedMenu);

				mitraDispatch(
					{ 
						type: 'UTILITIES', 
						value: { 
							currentMenu: 
							(menuKey === 'TEAM_PERFORMANCE' && mitraReducer?.mitraInfo.role === 'admin') ? 'PERFORMANCE_DASH' 
							: menuKey } 
					}
				);
			}
		}
    };

    const getSupportNotificationCount = () => {
        getCountOfUnreadSupportConversations(mitraReducer?.mitraInfo?.id)
        .then(res=> {
            if(res?.data?.status) {
                setNotificationCount(parseInt(res?.data?.data?.count));
            } else {
                setNotificationCount(0);
            }
        }).catch(err=> {
            console.log(err);
            setNotificationCount(0);
        })
    }

    useEffect(() => {
		if(location?.pathname === '/vp-search-page') {
			setShowSearchSection(false);
		} else {
            setShowSearchSection(true);
        }

        markActiveMenu(location);

        getSupportNotificationCount();

        supportTicketNotificationInterval = setInterval(() => {
            if (location.pathname === '/help-lp-auto' && supportTicketNotificationInterval) {
              clearInterval(supportTicketNotificationInterval);
              return;
            }
      
            // func call to get the unread conversations
            getSupportNotificationCount();
        }, 30000);

        return () => {
            if (supportTicketNotificationInterval) {
              clearInterval(supportTicketNotificationInterval);
            }
          };
	}, [location, mitraReducer?.mitraInfo?.id]);

    const handleLogout = () => {
		loginService
			.inValidateSession()
			.then((response) => {
				localStorage.clear()
				window.location.reload();
			})
			.catch((error) => {
				console.log('Error logging out: ', error);
			});
	};

    const markMenuActive = (menu) => {
        const modifiedDropdownMenu = [...dropdownMenu];

        for (let menuItem of modifiedDropdownMenu) {
            if(menuItem.menuKey === menu.menuKey) {
                menuItem.isActive = true;
            } else {
                menuItem.isActive = false;
            }
        }

        setDropdownMenu([...modifiedDropdownMenu]);
    }

    const onDropdownMenuClick = (menu) => {
        hideDropdownMenu();
        if(menu.menuKey === 'FAQ') {
            window.open(menu.link);
        } else {
            history.push(menu.path);
        }

        markMenuActive(menu);

        trackEvent('vp_menu_clicked',  {
            menu: menu?.menuLabel,
            phoneNumber: mitraReducer?.mitraInfo?.phoneNumber,
        }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    };

    const goBackToOldTheme = () => {
        const rootElement = document.getElementById('root');
        const mitraFeatures = mitraReducer?.allowedFeatures;

        rootElement.classList.remove('vahanPro');
        document.body.classList.remove('vahanPro');
    
        rootElement.classList.add('vahanOld');
        document.body.classList.add('vahanOld');

        mitraFeatures[CONSTANTS.VAHAN_PRO_ACCESS].isEnabledOverall = false;
        mitraReducer?.allowedFeatures?.[CONSTANTS.SUPPORT_AUTOMATION_ACCESS]?.isEnabledOverall 
        ? mitraDispatch({ type: 'USER_ROLE', value: ROLES.SUPPORT_AUTOMATION_ACCESS_USER })
        : mitraDispatch({ type: 'USER_ROLE', value: ROLES.ML });
        

        mitraDispatch({ type: 'ALLOWED_FEATURES', value: mitraFeatures });
        

        setShowVahanPro(true);
        setOpenChangeThemeModal(false);
    }

    const onChangeVahanProToggle = (checked) => {
        setOpenChangeThemeModal(true);

        trackEvent('vp_theme_toggled',  {
            phoneNumber: mitraReducer?.mitraInfo?.phoneNumber,
        }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }

    const content = (
        <div className='menu-container-ut'>
            <div className='d-flex justify-content-start align-items-center flex-gap-8'>
                <div 
                className='inter-semibold-16-24 plain-white d-flex align-items-center justify-content-center popover-profile-image-style'>
                    {mitraReducer?.mitraInfo?.name?.[0]}
                </div>
                <span className='inter-semibold-14-16 color-323232'>{mitraReducer?.mitraInfo?.name}</span>
            </div>
            <Divider style={{margin: '16px 0px'}}/>
            <div>
                {
                    dropdownMenu.map((menu, index)=> (
                        <div key={index} className={`d-flex align-items-center justify-content-between ${menu.isActive ? 'active-menu-highlight no-margin':''}`}>
                            <div 
                            onClick={()=>onDropdownMenuClick(menu)} 
                            className={`d-flex align-items-center flex-gap-8 cursor-pointer`} 
                            style={{padding: menu.isActive ? '16px 4px':'16px 0px'}}>
                                {menu.isActive ? menu.activeIcon : menu.icon}
                                <span className={`inter-regular-12-16 ${menu.isActive ? 'plain-white': 'color-323232'}`}>{menu.menuLabel}</span>
                            </div>

                            {
                                menu?.menuKey === 'HELP' && notificationCount ? (
                                    <div 
                                    className={`d-flex align-items-center justify-content-center inter-bold-12-18`}
                                    style={{
                                        marginRight: '8px',
                                        color: menu.isActive ? '#1D5FFF': '#FFF', 
                                        background: menu.isActive ? '#FFF': '#1D5FFF',
                                        padding: '0px 2px',
                                        borderRadius: '4px'
                                        }}>
                                        {notificationCount}
                                    </div>
                                ): null
                            }
                            
                        </div>
                    ))
                }

                <div className='theme-toggler d-flex align-items-center justify-content-between mt-16' style={{padding: '0px 4px'}}>
                    {
                        mitraReducer?.customBrandDetails?.brandName ? (
                            <Branding showBrandName={false} showProIcon={true} brandingLogoWidth={'26px'} brandingLogoHeight='auto'/>
                        ): (
                            <img alt="Logo" src="/image/vahan-pro.png" style={{width: '110px'}}/>
                        )
                    }
                    
                    <Switch size='small' defaultChecked checked={showVahanPro} onChange={onChangeVahanProToggle}/>
                </div>
            </div>
            <Divider style={{margin: '16px 0px'}}/>
            <div onClick={()=>handleLogout()} className='d-flex align-items-center flex-gap-8 cursor-pointer'>
                <LogoutIcon />
                <span className='inter-regular-12-16' style={{color: '#FF4545'}}>Logout</span>
            </div>
        </div>
    );

    const handleVisibleChange = (visible) => {
        setOpenDropdownMenu(visible); // Update visibility based on click
    };

  return (
    <>
        <div className='universal-topbar-main-container'>
            <Branding className="visibility-desktop"/>

            <div className='visibility-mobile align-items-center'>
                <div onClick={()=> setShowMobileMenu(true)}>
                    <MenuIcon />
                </div>

                {
                    mitraReducer?.customBrandDetails?.brandName ? (
                        <Branding showBrandName={false} showProIcon={false} brandingLogoWidth={'26px'} brandingLogoHeight='auto'/>
                    ): (
                        <img alt="Logo" src="/image/vahan-new-logo.png" style={{width: '40px'}}/>
                    )
                }
                
            </div>

            <div className='topbar-right-section'>
                {
                    showSearchSection ? (
                        <>
                            <div onClick={()=>history.push('/vp-search-page')} className='topbar-searchbox visibility-desktop'>
                                <SearchIcon strokeColor='#838383'/>
                                <span className='inter-regular-14-20 color-838383'>Search</span>
                            </div>
                            <div onClick={()=>history.push('/vp-search-page')} className='visibility-mobile'><SearchIcon strokeColor='#838383'/></div>
                        </>
                    ): null
                }

                {/* <BellIcon /> */}
                <div style={{position: 'relative'}}>
                    <Popover 
                    content={content} 
                    visible={openDropdownMenu}
                    onOpenChange={handleOpenChange}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    placement="bottomLeft"
                    onVisibleChange={handleVisibleChange}
                    title={null} 
                    trigger="click">
                        <div className='cursor-pointer user-avatar' onClick={()=>handleOpenChange(!openDropdownMenu)}>
                            {/* <Avatar className='d-flex align-items-center justify-content-center' icon={<UserOutlined />}/> */}
                            <div 
                            className='inter-semibold-16-24 plain-white d-flex align-items-center justify-content-center' 
                            style={{height: '32px',width: '32px',background: '#1d5fff', borderRadius: '50%'}}>
                                {mitraReducer?.mitraInfo?.name?.[0]}
                            </div>
                        </div>
                    </Popover>
                    {
                        notificationCount ? (<div className='notification-dot-indicator'></div>): null
                    }
                    
                </div>
            </div>
        </div>

        {
            showMobileMenu ? <MobileSidebar close={()=>setShowMobileMenu(false)}/>: null
        }

        {
            <Modal
            title={null}
            visible={openChangeThemeModal}
            className="leads-filter-modal"
            closable={false}
            maskClosable={false}
            destroyOnClose={true}
            bodyStyle={{borderRadius: '8px',padding: '0px'}}
            footer={null}
            >
                <>
                    <div className="leads-filter-header">
                        <span className='unbounded-semibold-20-24 plain-black'>Confirmation</span>
                        <div style={{cursor: 'pointer'}} onClick={()=>setOpenChangeThemeModal(false)}>
                            <CrossIcon height={18} width={18}/>
                        </div>
                    </div>

                    <div className='d-flex flex-column align-items-center justify-content-center new-call-note-main-container new-theme-shadow-light'>
                        <div 
                        className={`section-addition-main-container new-theme-shadow-light d-flex w-100 flex-column justify-content-center align-items-center`}>
                            <img alt="Logo" src="/image/stock-image.webp" style={{width: window.innerWidth< 800 ? '200px': '120px'}}/>
                            <span className='mt-24 inter-regular-16-24 color-252526'>Are you sure you want to go back to the old theme?</span>
                            <span className='mt-6 inter-semibold-16-24 color-252526'>Soon, it will be unavailable.</span>

                            <div className='vp-to-vahan d-flex align-items-center' style={{marginTop: '40px', gap: window.innerWidth< 800 ? '16px':'40px'}}>
                                {
                                    mitraReducer?.customBrandDetails?.brandName ? (
                                        <Branding 
                                        brandingLogoWidth={'26px'}
                                        showBrandName={window.innerWidth< 800 ? false: true} 
                                        brandingLogoHeight='auto'/>
                                    ): (
                                        <img alt="Logo" src="/image/vahan-pro.png" style={{width: window.innerWidth< 800 ? '140px':'200px'}}/>
                                    )
                                }
                                
                                <div style={{transform:'rotate(180deg)'}}>
                                    <LeftArrow strokeColor='#ff4545'/>
                                </div>

                                {
                                    mitraReducer?.customBrandDetails?.brandName ? (
                                        <Branding 
                                        brandingLogoWidth={'26px'}
                                        showProIcon={false} 
                                        showBrandName={window.innerWidth< 800 ? false: true} 
                                        brandingLogoHeight='auto'/>
                                    ): (
                                        <img alt="Logo" src="/image/vahan-swan.png" style={{width: window.innerWidth< 800 ? '100px':'143px'}}/>
                                    )
                                }
                                
                            </div>
                        </div>
                    </div>

                    <div className="leads-filter-footer flex-gap-8">
                        <Button className='secondary-btn-styles-v2 leads-filter-footer-button' onClick={()=>setOpenChangeThemeModal(false)}> 
                            <span className='btn-text-semibold-14-16 color-1D5FFF'>
                                Stay in
                                {
                                    mitraReducer?.customBrandDetails?.brandName ? ' Pro': ' VahanPro'
                                }
                            </span>
                        </Button>
                        <Button className='leads-filter-footer-button continue-to-old-theme-btn-styles' onClick={goBackToOldTheme}> 
                            <span className='btn-text-semibold-14-16 plain-white'>Continue</span>
                        </Button>
                    </div>

                </>
            </Modal>
        }
    </>
  )
}

export default UniversalTopBar