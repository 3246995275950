import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { AppContext } from '../../../App.js';
import { Button, Input, DatePicker, notification, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import commonFunction from '../../../utils/commonFunction.js';
import { postAdditionalInfo, uploadImageToS3 } from '../../../service/additionalInfoService.js';
import { getBlinkitDarkStoreFormFields, getBlinkitDarkStoreContants, submitBlinkitDarkStoreForm } from '../../../service/BlinkitDarkStoreAdditionalInfoService.js';

function BlinkitDarkStoreAdditionalInfoContainer() {
    const { mitraReducer, setSpin } = useContext(AppContext);
    const history = useHistory();
    const [ formFields, setFormFields ] = useState([]);
    const [ errorField, setErrorField ] = useState(null);
    const [ dropDownOptions, setDropDownOptions ] = useState({ State: [], City: [], Designation: [], Es_Name: [] });
    const [ stateOptions, setStateOptions ] = useState([]);
    const [ cityOptions, setCityOptions ] = useState([]);
    const [ designationOptions, setDesignationOptions ] = useState([]);
    const [ esNameSubLocationOptions, setEsNameSubLocationOptions ] = useState([]);
    const [ startDate, setStartDate ] = useState();
    const [ endDate, setEndDate ] = useState();
    const [ documents, setDocuments ] = useState({
        // property name resembles element name in the DB
        panCard: null,
        aadhaarCard: null,
        aadhaarCardBack: null,
        userSelfie: null,
        drivingLicense: null,
        resumeImage: null,
        voterCardFront: null,
    });
    const [ lead ] = useState(commonFunction.getAllsearchParams(history.location.search));

    const setValue = ( fieldIndex, value, key ) => {
        const modifiedFormFields = [...formFields];
        modifiedFormFields[fieldIndex].selectedValue = value;
        modifiedFormFields[fieldIndex].selectedKey = key;

        if (modifiedFormFields[fieldIndex].key === 'Secondary_IDV') {
            const selectedOption = modifiedFormFields[fieldIndex].options.find(opt => opt.value === value);
            const secondaryKey = selectedOption?.secondaryKey;

            // Clear secondary fields before updating their visibility
            modifiedFormFields.forEach(field => {
                if (field.isSecondaryField && field.showFor !== secondaryKey) { // checking whether the component is a secondary (variable) field and to be shown for the selected secondary field 
                    // Reset values for fields that should no longer be visible
                    field.selectedValue = null;
                    field.selectedKey = null;
                }
            });

            modifiedFormFields.forEach(field => {
                if (field.isSecondaryField) {
                    field.isVisible = field.showFor === secondaryKey;
                }
            });
        }

        if (modifiedFormFields[fieldIndex].key === 'State') {
            const selectedState = dropDownOptions.State.find(state => state.M_Name === value);
            const filteredCities = dropDownOptions.City.filter(city => city.M_ParentID === selectedState?.M_ID).map(city => ({
                key: city.M_ID,
                value: city.M_Name,
                label: city.M_Name,
            }));
            setCityOptions(filteredCities);
    
            // Clear the city selection in the form fields
            const cityFieldIndex = formFields.findIndex(field => field.key === 'City');
            if (cityFieldIndex !== -1) {
                modifiedFormFields[cityFieldIndex].selectedValue = null; // Reset city selection
                modifiedFormFields[cityFieldIndex].selectedKey = null; // Reset city selection
            }
        }

        if (modifiedFormFields[fieldIndex].key === 'City') {
            const selectedCity = dropDownOptions.City.find(city => city.M_Name === value);
            const filteredEsNameSubLocations = dropDownOptions.Es_Name.filter(location => location.M_ParentID === selectedCity?.M_ID).map(location => ({
                key: location.M_ID,
                value: location.M_Name,
                label: location.M_Name,
            }));
            setEsNameSubLocationOptions(filteredEsNameSubLocations);

            // Clear the Warehouse location selection in the form fields 
            const locationFieldIndex = formFields.findIndex(field => field.key === 'Es_Name_Sub_Location');
            if (locationFieldIndex !== -1) {
                modifiedFormFields[locationFieldIndex].selectedValue = null; // Reset location selection
                modifiedFormFields[locationFieldIndex].selectedKey = null;
            }
        }

        setFormFields([...modifiedFormFields]);
    }

    const validateInput = (regexString, inputValue) => {
        // no regex is specified => ignore check
        if (regexString === undefined) return true;

        const regex = new RegExp(regexString);
        return regex.test(inputValue);
    };

    const handleDateChange = ( date, fieldIndex ) => {
		if (date === null) {
			setStartDate(moment()).startOf('day');
            setValue(fieldIndex, null, null);
			setEndDate(moment()).endOf('day');
		}
		else {
            const selectedDate = moment(date).startOf('day');
			setStartDate(selectedDate);
            setValue(fieldIndex, selectedDate, null);
			setEndDate(moment(date).endOf('day'));
		}
	}

    const handleBeforeUpload = ( file ) => {
        return false;
    }

    const handleUpload = (info, type, index) => {
        showLoader(true);
        uploadImageToS3(info.file)
            .then( async(res) => {
                if (res?.data?.imageUrl) {
                    const submissionData = {
                        [type]: {
                            url: res.data.imageUrl,
                            originalName: info?.file?.name,
                            size: info?.file?.size
                        }
                    }
                    const response = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);
                    if(!checkErrors(response)) {
                        setDocuments({...documents, [type]: res?.data?.imageUrl});
                        setValue(index, res?.data?.imageUrl, null);
                    }
                    showLoader(false);
                } else {
                    showLoader(false);
                }
            })
            .catch(error => {
                showLoader(false);
                notification['error']({
                    message: 'Image upload failed, please try again later.',
                });
                console.log(error);
            })
    }

    const handleSubmit = async () => {
        for (let i = 0; i < formFields.length; i++) {
            // Skip validation for fields that are secondary and not selected secondary currently
            if (formFields[i]?.isSecondaryField && !formFields[i]?.isVisible) {
                continue;
            }
            /// regex
            if (formFields[i]?.regex) {
                if (!validateInput(formFields[i]?.regex, formFields[i]?.selectedValue)) {
                    setErrorField(formFields[i]?.key);
                    notification['error']({
                        message: `${formFields[i]?.label} not in valid format`
                    })
                    return;
                }
            }

            // Set read only fields from Basic Info in Form Field for payload
            if (formFields[i].key === 'Owner_Name') {
                formFields[i].selectedValue = lead.name;
            } else if (formFields[i].key === 'Contact_Number_of_Owner') {
                formFields[i].selectedValue = lead.phoneNumber;
            }
            // required field not filled
            if(formFields[i]?.required && !formFields[i]?.selectedValue) {
                setErrorField(formFields[i]?.key);
                notification['error']({
                    message: `${formFields[i]?.label} is required`
                })
                return;
            }
        }
        // create payload from selected values
        const payload = formFields.reduce((acc, field) => {
            if (['City', 'State', 'Designation', 'Es_Name_Sub_Location'].includes(field.key)) {
                acc[field.key] = field.selectedKey.key;
            } else if (field.key === 'Owners_DOB') {
                acc[field.key] = field.selectedValue.format('DD/MM/YYYY')
            } else {
                acc[field.key] = field.selectedValue;
            }
            return acc;
        }, {});
        const additionalData = {
            metaInfo: {
                ...lead
            }
        };
        const finalPayload = {
            ...payload,
            additionalData,
        };
        showLoader(true, 'Submitting Details');
        try {
            const response = await submitBlinkitDarkStoreForm(finalPayload);
            if(response?.data?.success) {
                notification['success'] ({
                    message: 'Details submitted successfully.'
                })

                history.replace({ pathname: "/recruiter-candidates" });
            } else {
                notification['error'] ({
                    message: `${response?.data?.message}` || 'Error submitting user data, please try again later.'
                })

                if (response?.data?.messageCode === 1) history.replace({ pathname: "/recruiter-candidates" }); // route only for duplicate number error
            }
        } catch ( error ) {
            notification['error'] ({
                message: `Error submitting details, please try again later.`
            })
            console.log(error);
        } finally {
            showLoader(false, 'Fetching Details');
        }
    }

    const showLoader = ( value, message = null ) => {
        setSpin({
            loading: value, 
            delay: null,
            tip: message || 'Updating Information'
        });
    }

    const checkErrors = ( res ) => {
        const newErrors = [];
        for (let key in res.data) {
            if (res.data[key] !== "200") {
                newErrors.push(`${key}: ${res.data[key]}`);
            }
        }
        if (newErrors.length) {
            alert(newErrors.join("\n"));
            return true;
        }
        return false;
    }

    const getFormFields = async () => {
        showLoader(true, 'Fetching Details');
        try {
            const response = await getBlinkitDarkStoreFormFields();
            if (response?.data?.success && response?.data?.formFields?.length) {
                setFormFields([...response?.data?.formFields]);
            } else {
                notification['error'] ({
                    message: 'Failed to fetch details, please try again later.'
                });
            }
        } catch ( error ) {
            notification['error'] ({
                message: 'Error fetching form details, please try again later.'
            });
        } finally {
            showLoader(false, 'Fetching Details');
        }
    }

    const getBlinkitDarkStoreConstants = async () => {
        try {
            const response = await getBlinkitDarkStoreContants();
            if (response?.data) {
                setDropDownOptions({
                    State: response?.data?.State || [],
                    City: response?.data?.City || [],
                    Designation: response?.data?.Designation || [],
                    Es_Name: response?.data?.["ES Name"] || []
                });
                const formattedStateOptions = response.data.State.map(state => ({
                    key: state.M_ID,
                    value: state.M_Name,
                    label: state.M_Name
                }));

                const formattedDesignationOptions = response.data.Designation.map(designation => ({
                    key: designation.M_ID,
                    value: designation.M_Name,
                    label: designation.M_Name
                }));

                setStateOptions(formattedStateOptions);
                setDesignationOptions(formattedDesignationOptions);
            }
        } catch (error) {
            console.error('Error fetching constants:', error);
            notification['error']({
                message: 'Failed to fetch constants'
            });
        }
    }

    useEffect(() => {
        getFormFields();
    }, []);

    useEffect(() => {
        getBlinkitDarkStoreConstants();
    }, []);

    return (
        <>
            <div className="AdditionalInfoScreen mainContentZ">
                <div className='header-text'> Blinkit Darkstore Additional Form </div>
                <ul>
                    <li style={{display: 'flex', textAlign: 'start', gap: '6px'}}>
                        <b> Note: </b>
                        <p> Please upload your candidates details and documents in this section. These will be directly submitted to Blinkit. </p>
                    </li>
                </ul>
                <div className='form-content-container'>
                    <div className="otp-verification-container">
                        <div className='container-header'> Additional Details </div>
                    </div>
                    <div className='pan-form-container'>
                        {
                            formFields?.map( ( field, index ) => (
                                <React.Fragment key={field?.key}>
                                    <div className='label mt-24'>
                                        { ( !field?.isSecondaryField || ( field?.isSecondaryField && field?.isVisible ) ) && field?.label }
                                        { ( !field?.isSecondaryField || ( field?.isSecondaryField && field?.isVisible ) ) && field?.required ? <sup>*</sup> : null }
                                    </div>
                                    {
                                        ( !field?.isSecondaryField || ( field?.isSecondaryField && field?.isVisible ) ) && (
                                            field?.type === 'input' ? (
                                                <Input 
                                                    maxLength={field?.maxLength || 500} // default as 500 characters TO-DO: test on 3r party API
                                                    placeholder={`Enter ${field?.label}`}
                                                    value={field?.key === 'Owner_Name' ? lead.name : field?.key === 'Contact_Number_of_Owner' ? lead.phoneNumber : field?.selectedValue}
                                                    onChange={
                                                        (e) => {
                                                            setValue(index, e.target.value, null);
                                                        }
                                                    }
                                                    readOnly={field?.key === 'Owner_Name' || field?.key === 'Contact_Number_of_Owner'}
                                                    className={errorField===field?.key ? 'input-error':'input'}
                                                />
                                            ) : null
                                        )
                                    }
                                    {
                                        field?.type === 'dropDown' ? (
                                            <Select 
                                                style={{ width: '100%', textAlign: 'start', marginTop: '8px' }}
                                                placeholder={`Select ${field?.label}`}
                                                value={field?.selectedValue}
                                                onChange={(value, key) => setValue(index, value, key)}
                                                optionLabelProp="value"
                                                options={
                                                    field?.key === 'State'
                                                        ? stateOptions
                                                        : field?.key === 'City'
                                                            ? cityOptions
                                                            : field?.key === 'Designation'
                                                                ? designationOptions
                                                                : field?.key === 'Es_Name_Sub_Location'
                                                                    ? esNameSubLocationOptions
                                                                    : field.options
                                                }
                                                className={errorField===field?.key ? 'input-error':'input'}
                                                showSearch={
                                                    field?.key !== 'Secondary_IDV' && 
                                                    field?.key !== 'Owner_Gender'
                                                }
                                                filterOption={(input, option) =>
                                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                }
                                            />
                                        ) : null
                                    }
                                    {
                                        field?.type === 'date' ? (
                                            <DatePicker 
                                                placeholder={`Select ${field?.label}`}
                                                onChange={(date) => handleDateChange(date, index)}
                                                value={field?.selectedValue}
                                                allowClear={false}
                                                className={errorField===field?.key ? 'input-error':'input'}
                                                inputReadOnly={true}
                                            />
                                        ) : null
                                    }
                                    {
                                        ( !field?.isSecondaryField || ( field?.isSecondaryField && field?.isVisible ) ) && (
                                            field?.type === 'upload' ? (
                                                field?.selectedValue ? (
                                                    <div style={{display: 'flex', gap: '8px'}}>
                                                        <img src={field?.selectedValue} width={150} height={150}/>
                                                        <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setValue(index, null)}>x</p>
                                                    </div>
                                                ) : (
                                                    <Upload
                                                        name="avatar"
                                                        listType="picture-card"
                                                        showUploadList={false}
                                                        accept="image/*" // TO-DO: jpeg only
                                                        className={errorField===field?.key ? 'input-error':''}
                                                        onChange={(info) => handleUpload(info, field?.uploadType, index)}
                                                        beforeUpload={handleBeforeUpload}
                                                    >
                                                        <div>
                                                            <Input type="file" accept="image/*" style={{ display: 'none' }} /> 
                                                            {/* // TO-DO: jpeg only */}
                                                            <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                                                        </div>
                                                    </Upload>
                                                )
                                            ) : null
                                        )
                                    }
                                </React.Fragment>
                            ))
                        }
                    </div>
                </div>
                <div className='button-container'>
                    <Button className='submit-button' onClick={()=>handleSubmit()}> Submit </Button>
                </div>
            </div>
        </>
    );
}

export default BlinkitDarkStoreAdditionalInfoContainer