import { Button, Input, notification, Radio, Upload } from 'antd'
import React, { useContext, useEffect, useRef, useState, useImperativeHandle } from 'react'
import { AppContext } from '../../App';
import {UploadOutlined} from '@ant-design/icons'
import { useHistory } from "react-router-dom";
import { postAdditionalInfo, uploadImageToS3 } from '../../service/additionalInfoService';
import { addDetailsForMitra, getMitraInfo } from '../../service/mlOnboardingService';

function VLOnboardingV2({ parent = "", reference, isFormSubmitted = () => { } }) {
    const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
    const [errorField, setErrorField] = useState(null);
    const [vlDetails, setVlDetails] = useState({});
    const scrollContainerRef = useRef(null);
    const history = useHistory()
    const [formValues, setFormValues] = useState({
        "haveAnOffice": null,
        "address": null,
        "bankName": null,
        "bankAccountHolderName": null,
        "bankAccountNumber": null,
        "bankAccountNumberCopy": null,
        "ifsc": null,
        "haveGSTNumber": null,
        "aadhaarCard": null,
        "aadhaarCardFileDetails": [],
        "panCard": null,
        "panCardFileDetails": [],
        "bankPassbookOrCancelledCheque": null,
        "bankPassbookOrCancelledChequeFileDetails": [],
        "gstinNumber": null,
        "gstCertificate": null,
        "gstCertificateFileDetails": [],
    });

    const setFormInformation = (data, field) => {
        setFormValues({...formValues, [field]: data})
    };

    const showLoader = (value) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: 'Updating Information...'  //Loading copy here
        })
      }
    
      const checkErrors = (res) => {
        const newErrors = [];
        for (let key in res.data) {
            if (res.data[key] !== "200") {
                newErrors.push(`${key}: ${res.data[key]}`)
            }
        }
    
        if(newErrors.length) {
            // setErrors([...newErrors]);
            alert(newErrors.join("\n"))
            return true;
        } 
        return false;
        }
    

    const handleUpload =(info, type) => { // type is one of the property of documents object
        showLoader(true);
    
        uploadImageToS3(info.file)
            .then(async(res)=> {
    
                if(res?.data?.imageUrl) {
    
                    const submissionData = {
                        [type]: {
                            url: res.data.imageUrl,
                            originalName: info?.file?.name,
                            size: info?.file?.size
                        }
                    }
    
                    const response = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, {phoneNumber: mitraReducer?.mitraInfo?.phoneNumber, name: mitraReducer?.mitraInfo?.name});
    
                    if(!checkErrors(response)) {
                        setFormInformation(res?.data?.imageUrl, type);

                        setFormValues({
                            ...formValues, 
                            [type]: res?.data?.imageUrl, 
                            [`${type}FileDetails`]: [{
                                url: res.data.imageUrl,
                                originalName: info?.file?.name,
                                size: info?.file?.size
                            }]
                        })
                    }
    
                    showLoader(false);
                } else {
                    showLoader(false);
                }
            }).catch(e=> {
                showLoader(false);
    
                notification['error']({
                    message: 'Image upload failed, please try again later.',
                  });
    
                console.log(e);
            })
    };
    
    const handleBeforeUpload = (file) => {
        // Return false to prevent upload
        return false;
    };

    const scrollToTop = () => {
        const scroll = scrollContainerRef.current?.scrollIntoView({
            behavior: "smooth", // Smooth scrolling
            block: "start", // Align to the top
          });
      };

    const checkIfAllRequiredValuesArePresentAndValid = () => {

        if(!formValues?.haveAnOffice) {
            notification['error']({
                message: 'Add office details to continue.'
            });
            scrollToTop();
            return false;
        }
        
        if(!formValues.address) {
            notification['error']({
                message: 'Add address to continue.'
            });

            scrollToTop();
            setErrorField('address');
            return false;
        }

        if(!formValues?.bankName) {
            notification['error']({
                message: 'Add bank name to continue.'
            });

            scrollToTop();
            setErrorField('bankName');
            return false;
        }

        if(!formValues?.bankAccountHolderName) {
            notification['error']({
                message: 'Add bank holder name to continue.'
            });

            scrollToTop();
            setErrorField('bankAccountHolderName');
            return false;
        }

        if(!formValues?.bankAccountNumber || !formValues?.bankAccountNumberCopy) {
            notification['error']({
                message: 'Add bank account number to continue.'
            });

            scrollToTop();
            return false;
        }

        if(formValues?.bankAccountNumber !== formValues?.bankAccountNumberCopy) {
            notification['error']({
                message: 'Bank account numbers donot match.'
            });
            setErrorField('bankAccountNumberCopy');
            return false;
        }

        if(!formValues?.ifsc) {
            notification['error']({
                message: 'Add bank ifsc to continue.'
            });
            setErrorField('ifsc');
            return false;
        }

        if(formValues?.ifsc && !(/^[A-Z]{4}0[A-Z0-9]{6}$/).test(formValues?.ifsc)) {

            notification['error'] ({
              message: 'Please enter correct IFSC code.'
            });
      
            setErrorField('ifsc');
            return false;
          } 
        
        if(!formValues?.haveGSTNumber) {
            notification['error'] ({
                message: 'Please enter GST details.'
              });
              return false;
        }

        if(formValues?.haveGSTNumber === 'yes' && !formValues?.gstinNumber) {
            notification['error'] ({
                message: 'Please enter GST number to continue.'
              });
              setErrorField('gstinNumber');
              return false;
        }

        if(formValues?.haveGSTNumber === 'yes' && formValues?.gstinNumber && !(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/).test(formValues?.gstinNumber)) {
            notification['error'] ({
                message: 'Please enter correct GST number to continue.'
              });
              setErrorField('gstinNumber');
              return false;
        }

        if(!formValues.aadhaarCard) {
            notification['error'] ({
                message: 'Please upload aadhar card to continue.'
              });
              setErrorField('aadhaarCard');
              return false;
        }

        if(!formValues.panCard) {
            notification['error'] ({
                message: 'Please upload PAN card to continue.'
              });
              setErrorField('panCard');
              return false;
        }

        if(!formValues.bankPassbookOrCancelledCheque) {
            notification['error'] ({
                message: 'Please upload bank passbook/cancelled cheque to continue.'
              });
              setErrorField('bankPassbookOrCancelledCheque');
              return false;
        }

        if(formValues?.haveGSTNumber === 'yes' && !formValues.gstCertificate) {
            notification['error'] ({
                message: 'Please upload GST certificate to continue.'
              });
              setErrorField('gstCertificate');
              return false;
        }
        return true;
    }

    const getFormattedFormDetails = () => {
        const formattedFormDetails = {
            "haveOffice": formValues.haveAnOffice,
            "presentAddress": formValues.address,
            "bankName": formValues.bankName,
            "bankAccountHolderName": formValues.bankAccountHolderName,
            "bankAccountNumber": formValues.bankAccountNumber,
            "bankIfscCode": formValues.ifsc,
            "haveGstNumber": "no",
            "aadhaarCard": formValues.aadhaarCardFileDetails,
            "panCard": formValues.panCardFileDetails,
            "bankPassbookOrCancelledCheque": formValues.bankPassbookOrCancelledChequeFileDetails,
            "submit": true
        }

        if(formValues.haveGSTNumber === 'yes') {
            formattedFormDetails.haveGstNumber = 'yes';
            formattedFormDetails.gstNumber = formValues.gstinNumber;
            formattedFormDetails.gstCertificate = formValues.gstCertificateFileDetails;
        }

        return formattedFormDetails;
    }

    const goToHomePage = () => {
        setTimeout(() => {
            setSpin({
                loading: false,
                delay: null,
                tip: 'Uploading Onboarding Details Please Wait...'
            });
            history.replace({ pathname: "/home" });
        }, 1000);
    };

    const submitForm = async() => {
        console.log(formValues);

        if(checkIfAllRequiredValuesArePresentAndValid()) {
            // format the payload and submit the form and move to home page
            const formattedFormPayload = getFormattedFormDetails();

            try {
                showLoader(true);
                const res = await addDetailsForMitra(formattedFormPayload, mitraReducer?.mitraInfo);

                if(!checkErrors(res)) {
                    notification['success']({
                        message: 'Submission successful.'
                    });

                    if (parent == "account-page") {
                        fetchInfo();
                        isFormSubmitted();
                    } else {
                        return goToHomePage();
                    }
                }
                showLoader(false);
            } catch (err) {
                showLoader(false);
                notification['error'] ({
                    message: 'Error submitting details! Please try again later.'
                })
            }
        }
    }

    useImperativeHandle(reference, () => ({
        submitForm,
    }));

    const fetchInfo = async () => {
        const populatedRes = await getMitraInfo();
        const populatedData = {}
        if (populatedRes?.data && populatedRes?.data?.length) {
            populatedRes.data.forEach(el => {
                try {
                    const parsedValue = JSON.parse(el.value)
                    if (typeof parsedValue === "number")
                        populatedData[el.type] = `${el.value}`
                    else
                        populatedData[el.type] = [{ ...parsedValue, originalName: parsedValue.url, storage: "url" }]
                } catch (error) {
                    populatedData[el.type] = el.value
                }
            })
        }
        if (populatedData && !Array.isArray(populatedData.panCard)) {
            delete populatedData.panCard;
        }

        console.log(populatedData)
        setVlDetails(populatedData);
    }

    useEffect(() => {
      fetchInfo();
    }, []);
    
    useEffect(() => {
        const formDetails = {};

        if(vlDetails?.presentAddress) {
            formDetails.address = vlDetails?.presentAddress
        }

        if(vlDetails?.haveOffice) {
            formDetails.haveAnOffice = vlDetails?.haveOffice
        }

        if(vlDetails?.bankName) {
            formDetails.bankName = vlDetails?.bankName
        }

        if(vlDetails?.bankAccountHolderName) {
            formDetails.bankAccountHolderName = vlDetails?.bankAccountHolderName
        }

        if(vlDetails?.bankAccountNumber) {
            formDetails.bankAccountNumber = vlDetails?.bankAccountNumber
            formDetails.bankAccountNumberCopy = vlDetails?.bankAccountNumber
        }

        if(vlDetails?.bankIfscCode) {
            formDetails.ifsc = vlDetails?.bankIfscCode
        }
        
        if(vlDetails?.haveGstNumber) {
            formDetails.haveGSTNumber = vlDetails?.haveGstNumber
        }

        if(vlDetails?.gstNumber) {
            formDetails.gstinNumber = vlDetails?.gstNumber
        }

        if(vlDetails?.aadhaarCard?.[0]?.url) {
            formDetails.aadhaarCard = vlDetails?.aadhaarCard?.[0]?.url;
            formDetails.aadhaarCardFileDetails = vlDetails?.aadhaarCard;
        }

        if(vlDetails?.panCard?.[0]?.url) {
            formDetails.panCard = vlDetails?.panCard?.[0]?.url;
            formDetails.panCardFileDetails = vlDetails?.panCard;
        }

        if(vlDetails?.bankPassbookOrCancelledCheque?.[0]?.url) {
            formDetails.bankPassbookOrCancelledCheque = vlDetails?.bankPassbookOrCancelledCheque?.[0]?.url;
            formDetails.bankPassbookOrCancelledChequeFileDetails = vlDetails?.bankPassbookOrCancelledCheque;
        }

        if(vlDetails?.gstCertificate?.[0]?.url) {
            formDetails.gstCertificate = vlDetails?.gstCertificate?.[0]?.url;
            formDetails.gstCertificateFileDetails = vlDetails?.gstCertificate;
        }

        setFormValues(formDetails);

    }, [vlDetails])
    

  return (
    <div className="AdditionalInfoScreen mainContentZ">
        
        <div className='header-text'>Vahan Leader Onboarding Information</div>


        <div className='form-content-container' ref={scrollContainerRef}>
            {/* Basic Information */}
            <div className='vehicle-type-container' style={{background: '#FFF'}}>

                <div className='container-header'>Basic Information</div>

                <div className="vehicle-details-container">
                    <div className='label mt-24'>Do you have an office? <sup>*</sup></div>
                    <Radio.Group onChange={(e)=>setFormInformation(e.target.value, 'haveAnOffice')} value={formValues.haveAnOffice}>
                        <Radio value={'yes'}>Yes</Radio>
                        <Radio value={'no'}>No</Radio>
                    </Radio.Group>

                    <div className='label mt-24'>Address<sup>*</sup></div>
                    <Input
                        // placeholder={`Enter address`}
                        value={formValues.address}
                        className={errorField==='address' ? 'input-error':'input'}
                        onChange={(e) => setFormInformation(e.target.value, 'address')}
                    />
                    <span className='text-align-start muted-text regular12-20 d-flex mt-2'>If you donot have an office, please provide your personal address.</span>
                </div>

            </div>

            {/* Bank Information */}
            <div className='vehicle-type-container' style={{background: '#FFF'}}>

                <div className='container-header'>Bank Information</div>

                <div className="vehicle-details-container">
                    <div className='label'>Bank Name<sup>*</sup></div>
                    <Input
                        // placeholder={`Enter address`}
                        value={formValues.bankName}
                        className={errorField==='bankName' ? 'input-error':'input'}
                        onChange={(e) => setFormInformation(e.target.value, 'bankName')}
                    />

                    <div className='label mt-24'>Bank Account Holder Name<sup>*</sup></div>
                    <Input
                        className={errorField==='bankAccountHolderName' ? 'input-error':'input'}
                        // placeholder={`Enter address`}
                        value={formValues.bankAccountHolderName}
                        onChange={(e) => setFormInformation(e.target.value, 'bankAccountHolderName')}
                    />

                    <div className='label mt-24'>Bank Account Number<sup>*</sup></div>
                    <Input
                        className={errorField==='bankAccountNumber' ? 'input-error':'input'}
                        // placeholder={`Enter address`}
                        value={formValues.bankAccountNumber}
                        onChange={(e) => setFormInformation(e.target.value, 'bankAccountNumber')}
                    />

                    <div className='label mt-24'>Verify Bank Account Number<sup>*</sup></div>
                    <Input
                        className={errorField==='bankAccountNumberCopy' ? 'input-error':'input'}
                        // placeholder={`Enter address`}
                        value={formValues.bankAccountNumberCopy}
                        onChange={(e) => setFormInformation(e.target.value, 'bankAccountNumberCopy')}
                    />
                    {
                       formValues.bankAccountNumber && formValues.bankAccountNumberCopy && (formValues.bankAccountNumberCopy !== formValues.bankAccountNumber) ? 
                       <span className='text-align-start primary-orange3 regular12-20 d-flex mt-2'>Account numbers donot match.</span>: null
                    }
    

                    <div className='label mt-24'>IFSC Code<sup>*</sup></div>
                    <Input
                        className={errorField==='ifsc' ? 'input-error':'input'}
                        maxLength={11}
                        // placeholder={`Enter address`}
                        value={formValues.ifsc}
                        onChange={(e) => setFormInformation((e.target.value).toUpperCase(), 'ifsc')}
                    />
                    <span className='text-align-start muted-text regular12-20 d-flex mt-2'>First 4 alphabets & Fifth character is 0 (zero) & Last six characters.</span>

                    <div className='label mt-24'>Do you GST Number? <sup>*</sup></div>
                    <Radio.Group onChange={(e)=>setFormInformation(e.target.value, 'haveGSTNumber')} value={formValues.haveGSTNumber}>
                        <Radio value={'yes'}>Yes</Radio>
                        <Radio value={'no'}>No</Radio>
                    </Radio.Group>

                    {
                        formValues.haveGSTNumber === 'yes' ? (
                            <>
                                <div className='label mt-24'>GSTIN Number<sup>*</sup></div>
                                <Input
                                    className={errorField==='gstinNumber' ? 'input-error':'input'}
                                    // placeholder={`Enter address`}
                                    value={formValues.gstinNumber}
                                    onChange={(e) => setFormInformation((e.target.value).toUpperCase(), 'gstinNumber')}
                                />
                            </>
                        ): null
                    }
                    
                </div>

            </div>

            {/* Document Proofs */}
            <div className='vehicle-type-container' style={{background: '#FFF'}}>
                <div className='container-header'>Documents</div>

                <div style={{margin: '16px',background: '#FFF'}}>

                    {
                        formValues.haveGSTNumber === 'yes' ? (
                            <>
                                <div className='label mt-24'>GST Certificate<sup>*</sup></div>
                                {
                                    formValues.gstCertificate ? (
                                        <div style={{display: 'flex', gap: '8px'}}>
                                            <img src={formValues.gstCertificate} width={150} height={150}/>
                                            <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setFormInformation(null, 'gstCertificate')}>x</p>
                                        </div>
                                    ): (
                                        <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            showUploadList={false}
                                            accept="image/*"
                                            onChange={(info)=>handleUpload(info, 'gstCertificate')}
                                            className={errorField==='gstCertificate' ? 'input-error':''}
                                            beforeUpload={handleBeforeUpload}
                                            >
                                            <div>
                                                <Input type="file" accept="image/*" style={{ display: 'none' }} />
                                                <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                                            </div>
                                        </Upload>
                                    )
                                }
                            </>
                        ): null
                    }

                    <div className='label mt-24'>Aadhaar Card (Front)<sup>*</sup></div>
                    {
                        formValues.aadhaarCard ? (
                            <div style={{display: 'flex', gap: '8px'}}>
                                <img src={formValues.aadhaarCard} width={150} height={150}/>
                                <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setFormInformation(null, 'aadhaarCard')}>x</p>
                            </div>
                        ): (
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                showUploadList={false}
                                accept="image/*"
                                onChange={(info)=>handleUpload(info, 'aadhaarCard')}
                                className={errorField==='aadhaarCard' ? 'input-error':''}
                                beforeUpload={handleBeforeUpload}
                                >
                                <div>
                                    <Input type="file" accept="image/*" style={{ display: 'none' }} />
                                    <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                                </div>
                            </Upload>
                        )
                    }

                    <div className='label mt-24'>PAN Card<sup>*</sup></div>
                    {
                        formValues.panCard ? (
                            <div style={{display: 'flex', gap: '8px'}}>
                                <img src={formValues.panCard} width={150} height={150}/>
                                <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setFormInformation(null, 'panCard')}>x</p>
                            </div>
                        ): (
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                showUploadList={false}
                                accept="image/*"
                                onChange={(info)=>handleUpload(info, 'panCard')}
                                className={errorField==='panCard' ? 'input-error':''}
                                beforeUpload={handleBeforeUpload}
                                >
                                <div>
                                    <Input type="file" accept="image/*" style={{ display: 'none' }} />
                                    <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                                </div>
                            </Upload>
                        )
                    }

                    <div className='label mt-24'>Cancelled Cheque / Passbook Image<sup>*</sup></div>
                    {
                        formValues.bankPassbookOrCancelledCheque ? (
                            <div style={{display: 'flex', gap: '8px'}}>
                                <img src={formValues.bankPassbookOrCancelledCheque} width={150} height={150}/>
                                <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setFormInformation(null, 'bankPassbookOrCancelledCheque')}>x</p>
                            </div>
                        ): (
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                showUploadList={false}
                                accept="image/*"
                                onChange={(info)=>handleUpload(info, 'bankPassbookOrCancelledCheque')}
                                className={errorField==='bankPassbookOrCancelledCheque' ? 'input-error':''}
                                beforeUpload={handleBeforeUpload}
                                >
                                <div>
                                    <Input type="file" accept="image/*" style={{ display: 'none' }} />
                                    <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                                </div>
                            </Upload>
                        )
                    }

                </div>
            </div>

            {
                parent == "account-page" ? null:(
                    <div className='button-container'>
                        <Button className='submit-button' onClick={submitForm}>Submit Form</Button>
                    </div>
                )
            }
        </div>
    </div>
  )
}

export default VLOnboardingV2