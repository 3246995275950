import React, { useContext } from 'react'
import '../../style/BulkReferralStyle.scss'
import { LoadingOutlined,ArrowRightOutlined, CheckCircleOutlined, UserOutlined, ClockCircleOutlined, ExclamationCircleOutlined, DownloadOutlined, ForwardFilled } from '@ant-design/icons';
import { Button, Divider, Popover, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../../../../service/engagementMonitoringService';
import CONSTANTS from '../../../../constants/constants';
import InfoIcon from '../../../../static/svg/InfoIcon';
import { AppContext } from '../../../../App';
import PeopleIcon from '../../../../static/svg/PeopleIcon';

const BulkReferralSummary = (
    {
        summaryData = [
            {
                    "totalLeads": 0,
                    "processedLeads": 0,
                    "failedCount": 0,
                    "errorReportUrl": "",
                
            }
        ]
        , isAiFilteringEnabled,
        validationInfoSummary={},
        onSubmit = ()=>1,
        isUploading=false
    }
) => {
    const history = useHistory();
    const {mitraReducer} = useContext(AppContext);

    const downloadCandidateStatusClicked = () => {
        trackEvent('bulk_referral_download_candidate_status_from_summary_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        const link = document.createElement('a');
        link.href = summaryData.errorReportUrl;
        link.download = 'leadsReferredStatus.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const onDownloadReport = () => {
        const link = document.createElement('a');
        link.href = validationInfoSummary.url;
        link.download = 'leadsReferredStatus.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const bulkSummaryContentAfterChecks = () => (
        <>
        
            <div className='br-summary-content lead-count-summary-content d-flex flex-row' style={{gap: '32px'}}>
                <span className='inter-bold-20-20' style={{color:'var(--primary-theme-color)'}}>Your file is ready for referral</span>
            </div>
            

            <div className='d-flex flex-column align-items-start mt-32 br-summary-new-contents flex-gap-8'>
                <div className='d-flex align-items-start flex-column w-100' style={{gap:'8px'}}>
                    <div className='d-flex align-items-center flex-gap-8 w-100 justify-content-center'>
                        <div>
                            <PeopleIcon height={24} width={24}/>
                        </div>
                        <span className='inter-bold-20-20'>{validationInfoSummary?.totalUploads} Total Leads Processed</span>
                    </div>
                    <div className='tickets-resolution-indicator-container' style={{marginTop: '20px'}}>

                        <div 
                        className='bu-tickets-resolved-indicator' 
                        style={{width: `${(validationInfoSummary?.valid*100)/validationInfoSummary?.totalUploads}%`}}></div>

                        {
                            validationInfoSummary?.rejected ? (
                                <div 
                                className='tickets-rejected-indicator' 
                                style={{
                                    width: `${(
                                        (validationInfoSummary?.valid*100)/validationInfoSummary?.totalUploads + 
                                        (validationInfoSummary?.rejected*100)/validationInfoSummary?.totalUploads
                                    )}%`, 
                                    left: 0
                                    }}>   
                                </div>
                            ): null
                        }

                        {
                            validationInfoSummary?.underReview ? (
                                <div 
                                className='tickets-warning-indicator' 
                                style={{
                                    width: `${(
                                        (validationInfoSummary?.valid*100)/validationInfoSummary?.totalUploads + 
                                        ((validationInfoSummary?.rejected || 0)*100)/validationInfoSummary?.totalUploads +
                                        (validationInfoSummary?.underReview*100)/validationInfoSummary?.totalUploads
                                    )}%`, 
                                    left: 0
                                    }}>
                                </div>
                            ): null
                        }
                        

                    </div>
                    <div className='d-flex justify-content-between w-100'>
                        <span className='inter-semibold-16-24' style={{color: '#31C824'}}>
                            {validationInfoSummary?.valid} Valid
                        </span>

                        {
                            validationInfoSummary?.rejected ? (
                                <span className='inter-semibold-16-24' style={{color: '#FF4D4F'}}>
                                    {validationInfoSummary?.rejected} Rejected
                                </span>
                            ): null
                        }

                        {
                            validationInfoSummary?.underReview ? (
                                <span className='inter-semibold-16-24' style={{color: '#D7AF1D'}}>
                                    {validationInfoSummary?.underReview} Under Review
                                </span>
                            ): null
                        }
                        
                    </div>
                </div>
                <Divider />
                <span className='inter-semibold-12-18'>Issues Found:</span>
                {
                    validationInfoSummary?.errors?.map((error, index) => (
                        error.count ?
                        <div key={index} className='d-flex align-items-center justify-content-between w-100 mt-6'>
                            <span 
                            className='inter-regular-16-24' 
                            style={{color: error?.type === 'error' ? '#FF4D4F':'#D7AF1D'}}>{`${error?.count} ${error?.name}`}</span>

                            <Popover
                            content={error?.info}
                            trigger={'hover'}>
                                <div>
                                    <InfoIcon height={20} width={20}/>
                                </div>
                            </Popover>
                        </div>: null
                    ))
                }
                {/* <div className='d-flex align-items-center justify-content-between w-100 mt-6'>
                    <span className='inter-regular-16-24' style={{color: '#FF4D4F'}}>40 Duplicate Numbers</span>
                    <InfoIcon height={20} width={20}/>
                </div>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <span className='inter-regular-16-24' style={{color: '#FF4D4F'}}>40 Suspicious Patterns</span>
                    <InfoIcon height={20} width={20}/>
                </div>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <span className='inter-regular-16-24' style={{color: '#D7AF1D'}}>10 Under Review</span>
                    <InfoIcon height={20} width={20}/>
                </div> */}

                <Button 
                onClick={onDownloadReport}
                className='align-self-center mt-12 vl-secondary-btn'>
                    <span style={{color:'var(--primary-theme-color)'}}>Download Report</span>
                </Button>
            </div>

            <span className='mt-16' style={{width: window.innerWidth < 800 ? '100%': '474px'}}>Leads under review will be checked further for validity with client and our databases. Check the report for more details.</span>
        </>
    );

    const bulkSummaryCtaAfterChecks = () => (
        // <div className='bottom-fixed-cta-bar d-flex align-items-center justify-content-end'>
        //     <Button className='vl-primary-btn bottom-fixed-button-width' style={{marginRight: '16px'}}>
        //         Complete Upload
        //     </Button>
        // </div>

        <div className='br-step-action-fields d-flex mobile-bottom-fixed'>
            <Divider style={{ margin: '4px 0px 16px' }}></Divider>
            <div className='br-step-btns d-flex'>
                <Button disabled={isUploading} loading={isUploading} className='vl-primary-btn bottom-fixed-button-width' style={{marginRight: '16px'}} onClick={onSubmit}>
                    Start Referral
                </Button>
            </div>
        </div>
    );

    // Not removing just in-case we need to roll back
    // const bulkSummaryContentWithoutChecks = () => (
    //     <>
    //         <div className='br-summary-info'>
    //             <div className='br-summary-total-container br-summary-section d-flex justify-content-between'>
    //                 <div className='d-flex align-items-center'>
    //                     <UserOutlined className='br-summary-total-icon d-flex' />
    //                     <div className='br-summary-total-txt br-summary-txt'>
    //                         Total Candidates
    //                     </div>
    //                 </div>

    //                 <div className='br-summary-processed-number br-summary-number-txt'>
    //                     {summaryData && summaryData.totalLeads ? summaryData.totalLeads : ""}
    //                 </div>
    //             </div>

    //             <div className='br-summary-processing-container br-summary-section d-flex justify-content-between'>
    //                 <div className='d-flex align-items-center'>
    //                     <ClockCircleOutlined className='br-summary-processing-icon' />
    //                     <div className='br-summary-processing-txt-parent d-flex br-summary-txt'>
    //                         <div className='br-summary-processing-txt'>
    //                             Processing Candidates for Referral
    //                         </div>
    //                         <div className='br-summary-processing-sub-txt'>
    //                             (takes about 2 hours for uniqueness check)
    //                         </div>
    //                     </div>
    //                 </div>

    //                 <div className='br-summary-processing-number br-summary-number-txt'>
    //                     {summaryData && summaryData.processedLeads ? summaryData.processedLeads : ""}
    //                 </div>
    //             </div>

    //             <div className='br-summary-error-container br-summary-section d-flex justify-content-between'>
    //                 <div className='d-flex align-items-center'>
    //                     <ExclamationCircleOutlined className='br-summary-error-icon d-flex' />
    //                     <div className='br-summary-error-txt br-summary-txt'>
    //                         Errors
    //                     </div>
    //                 </div>

    //                 <div className='br-summary-error-number br-summary-number-txt'>
    //                     {summaryData && summaryData.failedCount ? summaryData.failedCount : ""}
    //                 </div>
    //             </div>
    //         </div>
    //     </>
    // );

    // const bulkSummaryCtaWithoutChecks = () => (
    //     <div className='br-summary-btn-container d-flex align-items-center'>
    //         <Button 
    //         style={{border: '1px solid var(--primary-theme-color)'}}
    //         className='br-summary-goto-btn br-summary-btn-section d-flex align-items-center justify-content-center' 
    //         onClick={() => downloadCandidateStatusClicked()}>
    //             <DownloadOutlined className='br-summary-btn-img-orange' />
    //             <div className='br-summary-goto-btn-txt'>
    //                 Download Candidate Status
    //             </div>
    //         </Button>

    //         <Button className='br-summary-download-btn br-summary-btn-section d-flex align-items-center justify-content-center' onClick={() => history.push('/bulk-actions')}>
    //             <div className='br-summary-download-btn-txt'>
    //                 Go To Bulk Referrals
    //             </div>
    //             <ArrowRightOutlined className='br-summary-btn-img-white' />
    //         </Button>
    //     </div>
    // )

    return (
        <div className='br-step-parent br-summary-parent d-flex justify-content-between'>
            <div className='br-summary-content-container d-flex justify-content-center align-items-center'>
                {
                    bulkSummaryContentAfterChecks()
                }
            </div>

            {
                isAiFilteringEnabled ? (
                    <>
                <ForwardFilled style={{color: '#FF712D80',transform:'rotate(90deg)', fontSize: '48px'}}/>

                <div className='ai-filteration-indicator' style={{overflow:'scroll', marginBottom: '100px'}}>
                    {/* <img src='/image/ai-bot.png' style={{height: '32px', width: '40px'}} alt='ai-bot'/> */}
                    <span className='regular16-24 ai-filteration-text'>AI Filtering will start once candidates have been referred</span>
                </div>
            </>
                ): null
            }
            
            {
                bulkSummaryCtaAfterChecks()
            }

        </div>
    )
}

export default BulkReferralSummary; 