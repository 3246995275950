import React, { useState, useEffect, useContext, useReducer } from 'react';
import '../../css/report.scss'
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import PageHeaderComponent from '../common/PageHeader';
import SearchComponent from '../common/search.component';
import SectionLayout from '../common/SectionLayout.component';
import { getTeamLeaderBoardData, getPerformanceDashboardData, getPerformanceHistory, getCLientPeferenceData, DownloadPerformanceDashboardCandidatePageData, getMonthlyPayout, getMonthlyPayoutBreakup, downloadMonthlyPayoutBreakup, showPayoutDashboard } from './../../service/reportService'
import ClipLoader from 'react-spinners/ClipLoader';
import useGAEventsTracker from '../../service/useGSEventsTracker';
import TeamLeadsCard from './ReportTeamMemberCard.component';
// import useMoengage from '../../hooks/useMoengage';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import { getActiveClientsForFilter, getMitraTeamMembersList, disabledDate, disabledDateForPerformanceDashboard, loginForPayoutDashboard, disabledDateForPayoutDash, expiredLeadsDashboardInfo, sendPayoutDashboardPasswordResetUrl, getLocationForFilter, getMilestoneForFilter } from './../../service/commonService'
import { AppContext } from '../../App';
import { BarChartOutlined, InfoCircleOutlined, InteractionTwoTone, ProjectFilled, RocketFilled, DashboardTwoTone, DollarCircleTwoTone, RetweetOutlined, CaretRightOutlined, CaretUpOutlined, CaretDownOutlined, RightCircleTwoTone, EyeTwoTone, EyeInvisibleOutlined, ReloadOutlined, DownOutlined, DownloadOutlined, FilterOutlined, MobileOutlined } from '@ant-design/icons';
import { DatePicker, Select, Tag, Card, Skeleton, List, Typography, Button, Empty, Tooltip, Table, Modal, Input, message, Dropdown, Space, Menu, notification, Switch } from 'antd';
import { Column, Line } from '@ant-design/plots';
import OverallSummary from './OverallSummary';
import ReportLeaderboard from './ReportLeaderBoard.component';
import ClientPerformance from './ClientPerformance';
import { useParams } from "react-router-dom";
import SideSheet from "../common/SideSheet"
import Moment from 'react-moment';
import VerifyEmailPopup from './../common/VerifyEmailPopup.component';
import LocationFilterComponent from '../common/LocationFilter.component';
import { getDisplayableClientName } from './../../utils/commonFunction'
import MitraAppDownloadsSummary from './MitraAppDownloadsSummary';
import RunRateComponent from './RunRate.Component';
import PerformanceDashboardContainer from './PerformanceDashboardContainer';
import PayoutDashboardContainer from './PayoutDashboardContainer';

const { Option } = Select;
const { Text } = Typography;
const { RangePicker } = DatePicker;
const dateFormat = 'DD-MMMM YYYY';



const ReportPage = () => {
    const { mitraReducer, setAlert, mitraDispatch } = useContext(AppContext);
    const [mitra, setMitra] = useState({})
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const [isAdmin, setIsAdmin] = useState(mitraReducer?.mitraInfo?.role === 'admin' ? true : false);
    const history = useHistory();
    const { tab } = useParams();
    const [breadcrumb, setBreadcrumb] = useState([{ link: `/report/${tab}`, label: 'Report' }, { link: `/report/${tab}`, label: `${tab === 'payout-dashboard' ? 'Payout' : 'Performance'} ` }]);
    const [gridView, setGridView] = useState(false)
    const [payoutDashboardDefaultStartDate, setPayoutDashboardDefaultStartDate] = useState(moment().startOf('month'));
    const [performanceDashboardDefaultStartDate, setPerformanceDashboardDefaultStartDate] = useState(() => {
        const currentDate = moment().startOf('day'); 
        const sixDaysAgo = moment(currentDate).subtract(6, 'days');
        return sixDaysAgo.isBefore(moment(currentDate).startOf('month')) 
          ? moment(currentDate).startOf('month') 
          : sixDaysAgo;
    });
      
    const [endDate, setEndDate] = useState(moment());
    const [teamList, setTeamList] = useState([]);
    const [teamsFilterInfo, setTeamsFilterInfo] = useState([]);
    const [performanceDashboardData, setPerformanceDashboardData] = useState();
    const [performanceHistory, setPerformanceHistory] = useState();
    const [cientPerformance, setClientPerformance] = useState([]);
    const [selectedTeamMember, setSelectedTeamMember] = useState(0);
    const [teamLeaderBoardList, setTeamLeaderBoardList] = useState([]);
    const [topPerformerData, setTopPerformerData] = useState({})
    const [clientFilterList, setClientFilterList] = useState([])
    // const [createMoengageUser, trackEvent] = useMoengage();
    const [selectedClient, setSelectedClient] = useState('')
    const [selectedDate, setSelectedDate] = useState(moment().date() < 10 ? moment().subtract(1, "month").endOf("month") : moment())

    const [loaded, setLoaded] = useState(false);
    const ReportGAEventsTracker = useGAEventsTracker("Reports")
    const [showDateChip, setShowDateChip] = useState(false)
    const [downloadLoading, setDownloadLoading] = useState(false)
    const [monthlyPayoutData, setMonthlypayoutData] = useState([])
    const [monthlyPayoutDataBreakup, setMonthlypayoutDataBreakup] = useState({})
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [visibleAuth, setVisible] = useState(false);
    const [accessPasswordUsr, setAccessPasswordUsr] = useState('');
    const [payoutProcessingBanner, setPayoutProcessingBanner] = useState({})
    const [expiringLeads, setExpiringLeads] = useState([]);
    const [typeOfLead, setTypeOfLead] = useState('Eligible Leads');
    const [filterSider, setFilterSider] = useState(false);
    const [clickRangePicker, setClickRangePicker] = useState(false);
    const [filterApplied, setFilterApplied] = useState(true);
    const [showVerifyEmailPopup, setShowVerifyEmailPopup] = useState(false);
    const [downloadType, setDownloadType] = useState('');
    const [changeEmail, setChangeEmail] = useState(false);
    const [payoutDashboardHeadersArray, setPayoutDashboardHeadersArray] = useState([]);
    const [locationList, setLocationList] = useState([])
    const [disableDatesBefore, setDisableDatesBefore] = useState(moment());
    const [milestoneList, setMilestoneList] = useState([
        {
            label: 'F1 ~ First Trip',
            key: 'first_date_of_work',
            value: true,
        },
        {
            label: 'F20 ~ First 20 Trips',
            key: '20th_order_date',
            value: true,
        },
        {
            label: 'F50 ~ First 50 Trips',
            key: '50th_order_date',
            value: true,
        }
    ])
    const [isItemExpanded, setIsItemExpanded] = useState([
        { label: 'Base Pay', expanded: false },
        { label: 'Additions', expanded: false },
        { label: 'Deductions', expanded: false }
    ]);
    const [displayPayoutTopSection, setDisplayPayoutTopSection] = useState(true);

    const items = [
        {
            label: 'Payout Summary',
            key: '1',
            type: 'dashboard',
            icon: <DownloadOutlined />,
        },
        {
            label: 'Payout Summary and leads data',
            key: '2',
            type: 'leads',
            icon: <DownloadOutlined />,
        }
    ];

    const onClickTotalLead = (record) => {
        if (tab === 'payout-dashboard') {
            trackEvent('viewed_candidates_list_from_payout_dash_main_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
        }
        history.push({
            pathname: '/leads-page',
            state: {
                payoutname: record.campaignName || record.tag,
                expiringLeadsLabel: isNaN(record.key) ? record?.key : null,
                payload: {
                    limit: 50,
                    month: moment(selectedDate).format("MM"),
                    year: moment(selectedDate).format("YYYY"),
                    search: '',
                    offset: 0,
                    label: record.id || record.campaignName || record.tag,
                    tag: isNaN(record.key) ? record?.tag : null,
                    dashboard: tab,
                    client: selectedClient
                },
            },
        });
    }

    useEffect(() => {
        if (mitraReducer && mitraReducer.allowedFeatures) {
            let viewAccessRestricted = mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.isEnable
            if (viewAccessRestricted) {
                let temp_var = moment().subtract(mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.numberOfDays, 'days')
                setDisableDatesBefore(moment().subtract(mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.numberOfDays, 'days'))
            }
        }
    }, [mitraReducer])

    const expandedRowRender = (record, index, indent, expanded) => {
        trackEvent('viewed_breakdown_payout_dash_main_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        return (
            <Table
                className="payout-table"
                columns={monthlyPayoutDataBreakup.columns}
                dataSource={record.expandedRows}
                pagination={false}
                scroll={{ x: 320 }}
            />
        );
    };

    const getRequestData = () => {
        let selectedMitrasArray = [], selectedClientArray = [], selectLocationList = [], selectedMilestoneList = [], selectedTeamsArray = []; 
        for (let mitra of teamList) {
            if (mitra.value === true) {
                selectedMitrasArray.push(mitra.id)
            }
        }
        for (let team of teamsFilterInfo) {
            if (team.value === true) {
                selectedTeamsArray.push(team.id)
            }
        }
        for (let client of clientFilterList) {
            if (client.value === true) {
                selectedClientArray.push(client.label)
            }
        }
        locationList.map(status => {
            if (status.value) {
                selectLocationList.push(status.label)
            }
        })
        for (let milestone of milestoneList) {
            if (milestone.value === true) {
                selectedMilestoneList.push(milestone)
            }
        }
        if (selectLocationList.length > 0) {
            trackEvent('clicked_city_filter_performance_main_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        }
        let managerId = isAdmin ?
            selectedMitrasArray.length === 0 ?
                mitra.id : null
            : mitra.id
        let dataReq = {
            mitraIdArray: selectedMitrasArray.length > 0 ? selectedMitrasArray : [],
            clientNamesArray: selectedClientArray.length > 0 ? selectedClientArray : [],
            startDate: tab === 'performance-dashboard' ? moment(performanceDashboardDefaultStartDate).format('YYYY-MM-DD') : moment(payoutDashboardDefaultStartDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
            managerMitraID: managerId,
            graphView: gridView,
            typeOfLead: typeOfLead,
            locations: selectLocationList.length > 0 ? selectLocationList : [],
            milestones: selectedMilestoneList.length > 0 ? selectedMilestoneList : [],
            teams: selectedTeamsArray
        }
        return dataReq
    }

    const callAllPerformanceApis = async () => {
        const reqData = getRequestData()
        // getTeamLeaderBoardData({ ...reqData, limit: 5, managerMitraID: isAdmin ? mitra.id : mitra.managerMitraID, mitraId: isAdmin ? reqData.mitraId : '' })
        //     .then(response => {
        //         setTeamLeaderBoardList(response.data.data.data);
        //         setTopPerformerData(response.data.data.topPerformerData)
        //     })

        getCLientPeferenceData(getRequestData()).then((response) => {
            setClientPerformance(response.data)
        }).catch(
            console.log("error clientwise")
        );
        // getPerformanceHistory(getRequestData()).then((response) => {
        //     setPerformanceHistory(response.data);
        // });

        getPerformanceDashboardData(getRequestData()).then((response) => {
            setPerformanceDashboardData(response.data)
        }).catch(err => {
        });
    }

    const callPayoutApi = () => {
        let selectedClientArray = []
        for (let client of clientFilterList) {
            if (client.value === true) {
                selectedClientArray.push(client.label)
            }
        }
        if (selectedClientArray.length > 0) {
            setDisplayPayoutTopSection(false)
        }
        const data = {
            month: moment(selectedDate).format("MM"),
            year: moment(selectedDate).format("YYYY"),
            clientsArray: selectedClientArray
        }
        setMonthlypayoutData([])
        setMonthlypayoutDataBreakup({})
        if (selectedClientArray.length === 0) {
            getMonthlyPayout(data).then(res => {
                setMonthlypayoutData(res.data.data)
                setPayoutProcessingBanner(res.data.showPayoutBanner)
                setVisible(false)
            })
        }
        getMonthlyPayoutBreakup(data).then(res => {
            if (res.data.success) {
                let foundTotalLeads = res.data.columns.findIndex(function (element) {
                    return element.dataIndex == "totalLeads";
                });
                let foundAmount = res.data.columns.findIndex(function (element) {
                    return element.dataIndex == "amount";
                });

                let foundcampaignName = res.data.columns.findIndex(function (element) {
                    return element.dataIndex == "campaignName";
                });

                res.data.columns[foundcampaignName] = {
                    "title": "Campaign Name & Details",
                    "dataIndex": "campaignName",
                    "key": "campaignName",
                    render(text, record) {
                        return {
                            children: <div className='campaign-name'>{record.displayName && record.displayName.trim() ? record.displayName : text}</div>
                        };
                    }
                }

                res.data.columns[foundTotalLeads] = {
                    "title": "Total Count",
                    "dataIndex": "totalLeads",
                    "key": "totalleads",
                    render(text, record) {
                        return {
                            children: (record.campaignName === 'Base Pay' || record.parentName === 'Base Pay') ?
                                <div onClick={() => {
                                    onClickTotalLead(record)
                                }} className='totallead-text' style={{ textDecoration: 'underline', cursor: 'pointer' }}>{text}<CaretRightOutlined style={{ marginLeft: 6 }} twoToneColor="var(--primary-theme-color)" />
                                </div> : <div className='totallead-text'>{text}</div>
                        };
                    }
                }
                res.data.columns = [
                    res.data.columns[0],
                    Table.EXPAND_COLUMN,
                    ...res.data.columns.slice(1)
                ]
                setMonthlypayoutDataBreakup(res.data)
                const columnArray = res.data.columns.map((item) => {
                    return item.dataIndex
                })
                setPayoutDashboardHeadersArray(columnArray)

                setVisible(false)
            } else {
                setVisible(true)
            }
        }).catch(e => {
            localStorage.removeItem('payoutAccessToken');
            setVisible(true);
        })
    };

    const fetchExcludedLeadsInformation = () => {

        const monthYear = moment(selectedDate).format("MM") + '-' + moment(selectedDate).format("YYYY");

        expiredLeadsDashboardInfo(monthYear).then(response => {
            if (response && response.data && response.data.data && response.data.data.length === 0) {
                setExpiringLeads(['No Data']);
            } else {
                setExpiringLeads(response.data.data);
            }
        }).catch(error => {
            console.log(error, 'Expired Leads');
        })
    };

    useEffect(() => {
        getMilestoneForFilter().then(response => {
            setMilestoneList(response.data.milestoneList)
        })
        if (sessionStorage.getItem('payoutSelectedDate')) {
            setSelectedDate(moment(sessionStorage.getItem('payoutSelectedDate'), 'MM-YYYY'));
        }
        if (mitraReducer?.mitraInfo && !mitra.id) {
            setIsAdmin(mitraReducer?.mitraInfo?.role === 'admin' ? true : false)
            setMitra(mitraReducer?.mitraInfo)
            trackEvent('reports_section_visited', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
            getMitraTeamMembersList().then(response => {
                let list = []
                for (let user in response.data.data) {
                    list.push({ label: response.data.data[user].name, id: response.data.data[user].id, value: false });
                }
                setTeamList(list);
                getActiveClientsForFilter().then(data => {
                    setClientFilterList(data.data.activeClients)
                })
            });
            getLocationForFilter().then(response => {
                setLocationList(response.data.locationList)
            })
            if (mitraReducer?.mitraInfo?.role === 'admin') {
                const data = {
                    mitraId: mitraReducer?.mitraInfo?.id
                }
            }
            // fetchExcludedLeadsInformation();
        }
    }, [mitraReducer]);

    useEffect(() => {
        setClientPerformance([])
        if (teamList.length) {
            getCLientPeferenceData(getRequestData()).then((response) => {
                setClientPerformance(response.data)
            });
        }
    }, [gridView]);

    useEffect(() => {
        if (mitra && mitra.id && filterApplied) {
            setTeamLeaderBoardList([])
            setClientPerformance([])
            setPerformanceHistory()
            setPerformanceDashboardData()
            setLoaded(false)
            setBreadcrumb([{ link: `/report/${tab}`, label: 'Report' }, { link: `/report/${tab}`, label: `${tab === 'payout-dashboard' ? 'Payout' : 'Performance'} ` }]);
            if (tab === 'performance-dashboard') {
                trackEvent('viewed_performance_dash_main_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                callAllPerformanceApis();
                setBreadcrumb([{ link: `/report/${tab}`, label: 'Report' }, { link: `/report/${tab}`, label: `Performance` },{label: 'Overview'}]);
            }
            if (tab === 'payout-dashboard') {
                callPayoutApi();
                // fetchExcludedLeadsInformation();
                trackEvent('viewed_payout_dash_main_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            }
            if (tab !== 'payout-dashboard') {
                ReportGAEventsTracker('ViewedPerformanceDashboard');
            }
            setFilterApplied(false)
            setLoaded(true)
        }
    }, [mitra, tab, typeOfLead, filterApplied]);
    // teamList, startDate, endDate, selectedClient, selectedTeamMember, selectedDate, 

    const handleSheet = async (email) => {
        setDownloadLoading(true)
        if (tab !== 'payout-dashboard') {
            trackEvent('downloaded_sheet_performance_dash_main_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);

            let selectedMitrasArray = [], selectedClientArray = [], selectedLocationList = [], selectedTeamsList = [];
            for (let mitra of teamList) {
                if (mitra.value === true) {
                    selectedMitrasArray.push(mitra.id)
                }
            }

            for (let team of teamsFilterInfo) {
                if (team.value === true) {
                    selectedTeamsList.push(team.id)
                }
            }

            for (let client of clientFilterList) {
                if (client.value === true) {
                    selectedClientArray.push(client.label)
                }
            }
            for (let location of locationList) {
                if (location.value === true) {
                    selectedLocationList.push(location.label)
                }
            }

            const requestParams = {
                startDate: tab === "performance-dashboard" ? moment(performanceDashboardDefaultStartDate).format('YYYY-MM-DD') : moment(payoutDashboardDefaultStartDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
                clients: selectedClientArray.length > 0 ? JSON.stringify(selectedClientArray) : JSON.stringify([]),
                statuses: JSON.stringify([]),
                teamMemberIds: selectedMitrasArray.length > 0 ? JSON.stringify(selectedMitrasArray) : JSON.stringify([]),
                latestMilestonesFilter: JSON.stringify([]),
                locationFilter: JSON.stringify([]),
                search: '',
                typeOfLead: typeOfLead,
                locations: selectedLocationList.length > 0 ? selectedLocationList : [],
                email: email,
                changeEmail: changeEmail,
                teams: selectedTeamsList
            }
            DownloadPerformanceDashboardCandidatePageData(requestParams).then(response => {
                if (response.data.responseCandidatesUrl) {
                    console.log(response)
                    window.open(response.data.responseCandidatesUrl, '_blank')
                }
                else {
                    setAlert({
                        display: true,
                        text: response.data.message,
                        type: response.data.message.toLowerCase().includes('no') ? 'error' : 'success'
                    });
                }
                setDownloadLoading(false)
            })
        }
        else {
            let selectedClientArray = []
            for (let client of clientFilterList) {
                if (client.value === true) {
                    selectedClientArray.push(client.label)
                }
            }
            const data = {
                month: moment(selectedDate).format("MM"),
                year: moment(selectedDate).format("YYYY"),
                clientArray: selectedClientArray,
                type: downloadType,
                email: email,
                changeEmail: changeEmail
            }
            trackEvent('downloaded_sheet_payout_dash_main_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            downloadMonthlyPayoutBreakup(data).then(response => {
                console.log(response)
                if (response.data.responseCandidatesUrl) {
                    window.open(response.data.responseCandidatesUrl, '_blank')
                }
                else {
                    setAlert({
                        display: true,
                        text: response.data.message,
                        type: response.data.message.toLowerCase().includes('no') ? 'error' : 'success'
                    });
                }
                setDownloadLoading(false)
            }).catch(e => {
                localStorage.removeItem('payoutAccessToken');
                setVisible(true)
            })
        }
    }

    const downloadMenu = (
        <Menu onClick={(e) => {
            if (mitra.email == null || mitra.email === '') {
                setChangeEmail(true);
            }
            setShowVerifyEmailPopup(true)
            setDownloadType(items[e.key - 1].type);
        }}>
            {items.map((item, index) => {
                return <Menu.Item key={item.key}>
                    {item.label}
                </Menu.Item>
            })}
        </Menu>
    );

    const onclickDownloadPerformance = () => {
        if (mitra.email == null || mitra.email === '') {
            setChangeEmail(true);
        }
        setShowVerifyEmailPopup(true)
    }

    const deleteChip = (type, value) => {
        if (type === 'teamMember') {
            let teamListCopy = [...teamList];
            teamListCopy.forEach((teamMember) => {
                if (teamMember.label === value) {
                    teamMember.value = false;
                }
            });
            setTeamList(teamListCopy);
        }
        if (type === 'teamNames') {
            let teamListCopy = [...teamsFilterInfo];
            teamListCopy.forEach((team) => {
                if (team.label === value) {
                    team.value = false;
                }
            });
            setTeamsFilterInfo(teamListCopy);
        }
        if (type === 'client') {
            let clientFilterListCopy = [...clientFilterList];
            clientFilterListCopy.forEach((client) => {
                if (client.key === value) {
                    client.value = false;
                }
            });
            setClientFilterList(clientFilterListCopy);
        }
        else if (type === 'location') {
            let newCategory = [...locationList]
            newCategory.map((single_category, index) => {
                if (single_category.label === value.label) {
                    single_category.value = false
                }
            })
            setLocationList(newCategory)
        }
        else if (type === 'milestone') {
            let milestoneFilterListCopy = [...milestoneList];
            milestoneFilterListCopy.forEach((milestone) => {
                if (milestone.key === value) {
                    milestone.value = false;
                }
            });
            setMilestoneList(milestoneFilterListCopy);
        }
    }
    const onFilterClick = () => {
		setFilterSider(true)
		setClickRangePicker(true)
	};
    const openFilterSlider = () => {
		setClickRangePicker(false);
		setFilterSider(true)
	};
    const handlePicker = () => {
		setClickRangePicker(!clickRangePicker);
	}

    const handleDateRange = (dates) => {
        if (tab === 'payout-dashboard') {
            sessionStorage.setItem('payoutSelectedDate', moment(dates).format('MM-YYYY'))
            setSelectedDate(dates)
            trackEvent('used_month_selector_payout_dash_main_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
        }else {
            setClickRangePicker(!clickRangePicker);
            if (dates === null) {
                // for performance-dashboard it sets startDate to 7 days back from the current day
                tab === 'performance-dashboard' ? setPerformanceDashboardDefaultStartDate(moment().subtract(6, 'days').startOf('day')) : setPayoutDashboardDefaultStartDate(moment().startOf('month'));
                setEndDate(moment());
            }
            else {
                if(tab == 'performance-dashboard'){
                    setPerformanceDashboardDefaultStartDate(dates[0]);
                    setEndDate(dates[1]);
                }else{
                    setPayoutDashboardDefaultStartDate(dates[0]);
                    setEndDate(dates[1]);
                }
                const { pathname, search } = history.location;
                const newSearch = new URLSearchParams(search);
                newSearch.set('startDate', moment(dates[0]).format("YYYY-MM-DD"));
                newSearch.set('endDate', moment(dates[1]).format("YYYY-MM-DD"));
                console.log("NEW_SEARCH", newSearch.toString())
                history.push(`${pathname}?${newSearch.toString()}`);
            }
            trackEvent('used_date_selector_performance_dash_main_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
        }
    }

    // const handleClientChange = (e) => {
    //     setSelectedClient(e)
    //     ReportGAEventsTracker("ClickOnReportClientFilter")
    //     if(tab === 'payout-dashboard'){
    //         trackEvent('used_client_filter_payout_dash_main_page',{},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
    //     }
    // }
    const handleTypeOfLeadsChange = (e) => {
        setTypeOfLead(e)
        const { pathname, search } = history.location;
        const newSearch = new URLSearchParams(search);
        newSearch.set('typeOfLeads', e);
        history.push(`${pathname}?${newSearch.toString().replaceAll("+", "%20")}`);
        // ReportGAEventsTracker("ClickOnReportClientFilter")
        // if(tab === 'payout-dashboard'){
        //     trackEvent('used_client_filter_payout_dash_main_page',{},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
        // }
    }
    // const handleTeamMemberChange = (e) => {
    //     setSelectedTeamMember(e)
    // }

    const handleViewAll = () => {
        history.push({
            pathname: '/team-leader-board',
            search: '?' + 'Team Leader Board',
        });
        mitraDispatch({
            type: 'reportParams', value: {
                payoutDashboardDefaultStartDate, performanceDashboardDefaultStartDate, endDate, selectedClient, selectedTeamMember, teamList, typeOfLead, locationList, teamsFilterInfo
            }
        })
    }

    const onResetPasswordClick = () => {

        const key = `open${Date.now()}`;
        const btn = (
            <Button type="primary" size="small" onClick={() => history.push(`/report/payout-dashboard}`)}>
              Go to Payout Dashboard
            </Button>
        );

        notification.open({
            message: `Notification`,
            description: `A password ${mitraReducer?.mitraInfo?.password ? 'reset' : 'creation'} SMS has been sent to your phone number ${mitraReducer?.mitraInfo?.phoneNumber} and email ${mitraReducer?.mitraInfo?.email}`,
            btn,
            key,
          });
        let isNewPassword = mitraReducer?.mitraInfo?.password ? false : true
        sendPayoutDashboardPasswordResetUrl(isNewPassword);
        history.push('/home')
    }
    const handleOkClick = () => {
        setConfirmLoading(true);
        loginForPayoutDashboard({ password: accessPasswordUsr }).then(res => {
            if (res.data.success) {
                localStorage.setItem("payoutAccessToken", res.headers.payoutaccesstoken)
                setTimeout(callPayoutApi(), 5000)
                setVisible(false);
            } else {
                message.error("Invalid credentials!")
            }
            setConfirmLoading(false);
        }).catch((e) => {
            message.error("Invalid credentials!")
            setConfirmLoading(false);
        })
    }

    const getButtons = () => {
        let buttonsArr = [];
        if (mitraReducer?.mitraInfo?.password) {
            buttonsArr.push(
                <Button
                    onClick={onResetPasswordClick}
                    type="secondary">
                    <Text >Reset Password</Text>
                </Button>
            );
            buttonsArr.push(
                <Button
                    onClick={handleOkClick}
                    type="primary">
                    <Text style={{ color: '#fff' }}> OK </Text>
                </Button>
            )
        }
        return buttonsArr;
    }
    const configLinePlot = {
        data: performanceHistory?.data?.values,
        xField: 'date',
        yField: 'value',
        seriesField: 'category',
        style: { backgroundColor: "#fff" },
        animation: {
            appear: {
                animation: 'path-in',
                duration: 5000,
            },
        },
        smooth: true,
        color: ['var(--primary-theme-light)', 'var(--primary-theme-color)', '#A0D911', '#52C41A', '#237804', '#13C2C2'],
    };

    const configColumnPlot = {
        data: cientPerformance?.data,
        xField: 'client',
        yField: 'value',
        seriesField: 'type',
        isGroup: 'true',
        columnStyle: {
            radius: [5, 5, 0, 0],
        },
        style: { backgroundColor: "#fff" },
        tooltip: {
            showMarkers: false,
            // enterable: true,
            domStyles: {
                'g2-tooltip': {
                    padding: '10px',
                    paddingTop: '0',
                    width: '216px'
                },
                'g2-tooltip-list': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    fontFamily:'var(--primary-font-family)',
                    fontWeight: '500'
                },
                'g2-tooltip-list-item': {
                    margin: '3px'
                },
                'tooltip-color-shape': {
                    display: 'flex',
                    height: '8px',
                    width: '12px',
                    marginRight: '5px'
                },
                'tooltip-list-wrapper': {
                    padding: '10px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '0px'
                },
                'tooltip-value': {
                    fontFamily:'var(--primary-font-family)',
                    fontWeight: '700'
                }
            },
            customContent: (title, items) => {
                return <div align='center'>
                    <img className="imgResponse" alt={title} src={"/image/" + title + ".png"} />
                    <List
                        className="demo-loadmore-list"
                        itemLayout="horizontal"
                        dataSource={items}
                        renderItem={item => (
                            <div className='tooltip-list-wrapper'>
                                <span className='tooltip-list-wrapper'>
                                    <span className='tooltip-color-shape' style={{ backgroundColor: item.color }}></span>
                                    <span>{item.name}</span>
                                </span>
                                <span className='tooltip-value'>{item.value}</span>
                            </div>
                        )}
                    />
                </div>
            },
        },
        color: ['var(--primary-font-family)', 'var(--primary-theme-color)', '#A0D911', '#52C41A', '#237804', '#13C2C2'],
    };


    const submitFilter = () => {
        setFilterApplied(true)
    }

    const clearAll = () => {
        teamList.map(item => {
            item.value = false;
        })
        teamsFilterInfo.map(item => {
            item.value = false;
        })
        clientFilterList.map(item => {
            item.value = false;
        })
        locationList.map(item => {
			item.value = false;
		})
        milestoneList.map(item => {
			item.value = false;
		})
        tab === "performance-dashboard" ? setPerformanceDashboardDefaultStartDate(setPerformanceDashboardDefaultStartDate(() => {
            const currentDate = moment().startOf('day');
            const sixDaysAgo = moment(currentDate).subtract(6, 'days');
            return sixDaysAgo.isBefore(moment(currentDate).startOf('month'))? moment(currentDate).startOf('month'): sixDaysAgo;
            })) : setPayoutDashboardDefaultStartDate(moment().startOf('month'));
        setEndDate(moment());
        setFilterApplied(true)
    }

    const applyFilterCondition = () => {
        let result = false;
        let arr = [...teamList, ...clientFilterList, ...teamsFilterInfo];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].value === true) {
                result = true;
                break;
            }
        }
        return result;
    }

    const onClickTags = (label, index) => {

        if (label === 'Clients') {
            clientFilterList[index].value = !clientFilterList[index].value;
        }
        if (label === 'Team Member') {
            teamList[index].value = !teamList[index].value;
        }
        if (label === 'Teams') {
            teamsFilterInfo[index].value = !teamsFilterInfo[index].value;
        }
        if (label === 'Location') {
            locationList[index].value = !locationList[index].value;
        }
        if (label === 'Status (Choose up to 5 statuses)') {
            if (milestoneList.filter(item => item.value === true).length <= 4 || milestoneList[index].value === true)
                milestoneList[index].value = !milestoneList[index].value;
        }
        forceUpdate();
    }

    const preventDefault = (e, label, item) => {
        e.preventDefault()
        deleteChip(label, item)
        setFilterApplied(true)
    }

    const fetchDisabledDates = (current, mitraJoiningDate) => {
        if ((current && current > moment().endOf('day').valueOf()) || (current && current < disableDatesBefore.endOf("day"))) {
            return true;
        }
        // Can not select days before joining date
        else if ((current && current < moment(mitraJoiningDate).startOf('day').valueOf()) || (current && current < disableDatesBefore.endOf("day"))) {
            return true;
        }
        else {
            return false;
        }
    }

    const commonTag = (label, arrObj) => {
        return <div className='tag-containers'>
            <div>{label}</div>
            {
                label === "Date Range" || label === "Month Selection" ?
                    <Space>
                        {
                            tab === "performance-dashboard" ?
                                <RangePicker
                                    onClick={handlePicker}
                                    open={clickRangePicker}
                                    defaultValue={[moment(performanceDashboardDefaultStartDate, dateFormat), moment(endDate, dateFormat)]}
                                    format={dateFormat}
                                    onChange={handleDateRange}
                                    ranges={{
                                        Today: [moment().startOf('day'), moment().endOf('day')],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                    }}
                                    disabledDate={(current) => { return disabledDateForPerformanceDashboard(current, moment(mitra.createdAt).valueOf()) }}
                                    numberOfMonths={1}
                                    allowClear
                                />
                                :
                                <DatePicker
                                    onChange={handleDateRange}
                                    format="MMMM YYYY"
                                    allowClear={false}
                                    picker="month"
                                    value={selectedDate}
                                    disabledDate={disabledDateForPayoutDash}
                                    defaultValue={selectedDate}
                                    style={{ width: 150 }}
                                />
                        }
                    </Space>
                    :
                    label === "Location" ?
                        <LocationFilterComponent
                            locationList={locationList}
                            onClickTags={onClickTags}
                            label={label}
                            setLocationList={setLocationList}
                        />
                        :
                        <div className='tag-wrapper-container'>
                            {arrObj.map((item, index) => {
                                return <Tag className={!item.value ? 'tags' : 'tags tag-active'}
                                    key={index}
                                    // closable={item.value}
                                    onClick={() => { onClickTags(label, index) }}
                                >
                                    {label === "Clients" ? getDisplayableClientName(item.label) : item.label}
                                </Tag>
                            })}
                        </div>
            }
        </div>
    }
    function onExpandPayoutDashItem(item) {
        const newExpendedArray = [...isItemExpanded]
        for (let i = 0; i < newExpendedArray.length; i++) {
            if (newExpendedArray[i].label === item) {
                newExpendedArray[i].expanded = !newExpendedArray[i].expanded
            }
        }
        setIsItemExpanded(newExpendedArray)
    }

    function showDownArrow(item) {
        for (let i = 0; i < isItemExpanded.length; i++) {
            if (isItemExpanded[i].label === item) {
                return isItemExpanded[i].expanded
            }
        }
    }

    return (
        <>
            <div className={`ReportPage mainContentZ ${mitraReducer?.allowedFeatures?.[CONSTANTS.VAHAN_PRO_ACCESS]?.isEnabledOverall ? 'vahan-pro-component-container': ''}`}>
                <Modal
                    title={<Text style={{ display: 'flex', alignItems: 'center' }} strong>Authentication</Text>}
                    visible={visibleAuth && tab === 'payout-dashboard' && !localStorage.getItem("payoutAccessToken")}
                    onOk={() => {
                    }}
                    onCancel={() => {
                        history.goBack();
                    }}
                    footer={getButtons()}
                >
                    {
                        mitraReducer?.mitraInfo?.password ?
                            <Input.Password
                                placeholder="Input password to access payout dashboard"
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                onChange={(e) => {
                                    setAccessPasswordUsr(e.target.value)
                                }}
                                defaultValue={accessPasswordUsr}
                            /> :
                            <Button
                                onClick={onResetPasswordClick}
                                type="secondary">
                                <Text > Create New Password</Text>
                            </Button>
                    }
                    {/* <div className='forgot-payout-password'>Get in touch with your Vahan Account Manager for password</div> */}
                </Modal>

                {
                    !mitraReducer?.allowedFeatures?.[CONSTANTS.VAHAN_PRO_ACCESS]?.isEnabledOverall ? (
                        <div className="header">
                            <>
                                <PageHeaderComponent dropDownObj={{
                                    dropDownArr: [
                                        {
                                            value: 'performanceDashboard',
                                            route: '/report/performance-dashboard',
                                            label: 'Performance ',
                                            disabled: false

                                        },
                                        {
                                            value: 'payoutDashboard',
                                            route: '/report/payout-dashboard',
                                            label: 'Payout',
                                            disabled: !showPayoutDashboard(mitraReducer?.mitraInfo)
                                        }
                                    ],
                                    defaultValue: tab
                                }} breadcrumb={breadcrumb} />
                            </>
                        </div>
                    ): (
                        <span className='unbounded-semibold-24-28 plain-black hero-header' style={{background: 'var(--primary-bg-color)'}}>{tab === 'performance-dashboard' ? 'Performance': 'Payouts'}</span>
                    )
                }
                

                <div className='containerMiddle' style={{background: 'var(--primary-bg-color)'}}>

                    {
                    tab === 'performance-dashboard' ? (
                        <PerformanceDashboardContainer 
                        typeOfLead={typeOfLead}
                        startDate={performanceDashboardDefaultStartDate}
                        endDate={endDate}
                        clientFilterList={clientFilterList}
                        selectedTeamMember={selectedTeamMember}
                        teamList={teamList}
                        teamsFilterInfo={teamsFilterInfo}
                        locationList={locationList}
                        milestoneList={milestoneList}
                        performanceDashboardData={performanceDashboardData}
                        gridView={gridView}
                        cientPerformance={cientPerformance}
                        configColumnPlot={configColumnPlot}
                        teamLeaderBoardList={teamLeaderBoardList}
                        loaded={loaded}
                        topPerformerData={topPerformerData}
                        setGridView={setGridView}
                        performanceHistory={performanceHistory}
                        configLinePlot={configLinePlot}
                        handleViewAll={handleViewAll}
                        applyFilterCondition={applyFilterCondition}
                        isAdmin={isAdmin}
                        tab={tab}
                        setFilterSider={setFilterSider}
                        downloadLoading={downloadLoading}
                        onclickDownloadPerformance={onclickDownloadPerformance}
                        onFilterClick={onFilterClick}
                        openFilterSlider={openFilterSlider}
                        downloadMenu={downloadMenu}
                        preventDefault={preventDefault}
                        selectedDate={selectedDate}
                        getDisplayableClientName={getDisplayableClientName}
                        selectedClient={selectedClient}
                        showDateChip={showDateChip}
                        deleteChip={deleteChip}
                        setBreadcrumb={setBreadcrumb}
                        />
                    ): null
                    }

                    {
                        !visibleAuth && localStorage.getItem('payoutAccessToken') && tab === 'payout-dashboard' &&
                        <PayoutDashboardContainer 
                        displayPayoutTopSection={displayPayoutTopSection}
                        selectedDate={selectedDate}
                        monthlyPayoutData={monthlyPayoutData}
                        payoutProcessingBanner={payoutProcessingBanner}
                        monthlyPayoutDataBreakup={monthlyPayoutDataBreakup}
                        payoutDashboardHeadersArray={payoutDashboardHeadersArray}
                        onExpandPayoutDashItem={onExpandPayoutDashItem}
                        showDownArrow={showDownArrow}
                        onClickTotalLead={onClickTotalLead}
                        applyFilterCondition={applyFilterCondition}
                        isAdmin={isAdmin}
                        tab={tab}
                        setFilterSider={setFilterSider}
                        downloadLoading={downloadLoading}
                        onclickDownloadPerformance={onclickDownloadPerformance}
                        downloadMenu={downloadMenu}
                        preventDefault={preventDefault}
                        getDisplayableClientName={getDisplayableClientName}
                        selectedClient={selectedClient}
                        showDateChip={showDateChip}
                        deleteChip={deleteChip}
                        selectedTeamMember={selectedTeamMember}
                        startDate={payoutDashboardDefaultStartDate}
                        endDate={endDate}
                        clientFilterList={clientFilterList}
                        teamList={teamList}
                        locationList={locationList}
                        milestoneList={milestoneList}
                        />
                    }
                </div>
            </div>

            {
                filterSider &&
                <SideSheet submit={
                    {
                        disabled: false,
                        onClick: submitFilter
                    }}
                    clearAll={{ disabled: false, onClick: clearAll }}
                    sidername={"Filter for Reports"}
                    onDismiss={() => { setFilterSider(false) }}
                    performance={true}>
                    <div className='filter-home-wrapper'>
                        {
                            tab !== 'payout-dashboard' ?
                                <>
                                    {commonTag("Status (Choose up to 5 statuses)", milestoneList)}
                                    <hr style={{color: '#a7a7a7' }} />
                                    {commonTag("Date Range", null)}
                                    {
                                        // mitraReducer?.mitraInfo?.role === CONSTANTS.roles.ADMIN ? commonTag('Teams', teamsFilterInfo): null
                                    }
                                    {
                                       mitraReducer?.mitraInfo?.role !== CONSTANTS.roles.TEAM_MEMBER ? commonTag("Team Member", teamList): null
                                    }
                                    {commonTag("Clients", clientFilterList)}
                                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'justify', marginBottom: '20px' }}>
                                        <label style={{ marginBottom: '4px' }}>Type Of Lead</label>
                                        <Select
                                            className="Dropdown-control filter-label"
                                            style={{border: 0}}
                                            value={typeOfLead}
                                            placeholder="Type of leads"
                                            onChange={handleTypeOfLeadsChange}
                                        >
                                            <Option key={"Eligible Leads"} value={"Eligible Leads"}>Eligible Leads</Option>
                                            <Option key={"All Leads"} value={"All Leads"}>All Leads</Option>
                                        </Select>
                                    </div>
                                    {commonTag("Location", locationList)}

                                </>
                                :
                                <>
                                    {commonTag("Month Selection", null)}
                                    {commonTag("Clients", clientFilterList)}
                                </>
                        }
                    </div>
                </SideSheet>
            }
            {
                showVerifyEmailPopup &&
                (
                    <VerifyEmailPopup mitra={mitra} visible={showVerifyEmailPopup} closePopup={setShowVerifyEmailPopup} downloadFunction={handleSheet} changeEmail={changeEmail} setChangeEmail={setChangeEmail}></VerifyEmailPopup>
                )
            }
        </>
    )
}

export default ReportPage
